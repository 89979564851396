import React, {Component} from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import AppBar from '@mui/material/AppBar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {signOut} from '../actions/actions';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Image from 'material-ui-image';
import Typography from '@mui/material/Typography/Typography';
import { FormattedMessage } from 'react-intl';
import { setCurrentTeaching, } from '../actions/actions'

const styles = theme =>  ({
  appBar:{
    backgroundColor: '#19A3E2'
  },
  link:{
    textDecoration: 'none',
    color:'inherit',
  },
  logoContainer:{
    height:'200px',

  },
  navigation:{
    borderStyle:'solid',
    borderWidth:'1px',
    borderTopColor:'#e5e5e5',
    borderBottomColor:'#e5e5e5',
    borderRightColor:'white',
    borderLeftColor:'white',
    padding:'2px'
  },
  headerText:{
    fontFamily:'Raleway, Arial, Helvetica, sans-serif',
    fontSize:'30px',
    [theme.breakpoints.down('sm')]:{
      fontSize:'20px',
      paddingLeft:'30px'
    },
    fontWeight: '300'
  },
  customFont:{
    '&:hover':{
      color: '#1aa2e2'
    },
    textDecoration: 'none',
    fontSize:'13px',
    fontWeight: '600',
    letterSpacing:'3px',
    color:'#333333',
    fontFamily:'Raleway, Arial, Helvetica, sans-serif',
  }
});

class HeaderBar extends Component {
      // Current teaching is persisted, so refreshes will retain current teaching.
      // When navigating to page, we do not want to persist the teaching that was there, so reset
      componentDidMount(){
        console.log('version', process.env.REACT_APP_VERSION, 'configuration', process.env.REACT_APP_ENV);
        var perfEntries = performance.getEntriesByType("navigation");
        if (perfEntries && perfEntries.length > 0){
          if (perfEntries[0].type === "navigate" || perfEntries[0].type === "prerender"){
            this.props.setCurrentTeaching(null);
          }
        }
      }

      render(){
        const { classes } = this.props;
        return (
          <React.Fragment>
            <AppBar
              position="static" 
              className={classes.appBar}>
              <Toolbar>
                <Grid item>
                  <Typography color="inherit" noWrap>
                  <FormattedMessage
                    description="Greeting to welcome the user"
                    defaultMessage="Hi, {name}!"
                    values={{
                      name: this.props.currentUser.display_name
                    }}
                  />
                  </Typography>
                </Grid>
                <Grid
                  justifyContent="flex-end"
                  container>
                  <Grid item>                 
                    <Link to="/Login" className={classes.link} onClick={() => this.props.signOut()}>
                      <Button color="inherit">
                      <FormattedMessage
                        description="Button to log out"
                        defaultMessage="Logout"
                      />
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
              </Toolbar>                 
            </AppBar>
            <Grid container 
              alignItems="center"
              alignContent="center"
              justifyContent="space-between">
              <Grid item>
                <a href={process.env.REACT_APP_SMART_SITE_BASE}>
                  <Image style={{padding:'89px'}}
                    imageStyle={{width:'150px', height:'118px', padding:'30px'}}                     
                    src='/HLFLogo.jpg'
                  />                
                </a>
              </Grid>
                <Grid item xs={6} md={5}>
                  <div className={classes.headerText}>
                    HARBOR LIGHT FELLOWSHIP
                  </div>
                </Grid>
            </Grid>
            <Grid container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  className={classes.navigation}>
                  <Grid style={{padding:"10px"}}>
                    <a href={process.env.REACT_APP_SMART_SITE_BASE}
                      className={classes.customFont}
                      style={this.props.currentPage === 'Home' ? {color:'#1aa2e2'} : {}}
                      >
                        <FormattedMessage
                          description="Link to home page"
                          defaultMessage="HOME"
                        />
                    </a>
                  </Grid>
                  <Grid style={{padding:"10px"}}>

                    <Link to="/Instructions" className={classes.link}>
                        <div
                        className={classes.customFont}
                        style={this.props.currentPage === 'Instructions' ? {color:'#1aa2e2'} : {}}
                        >
                          <FormattedMessage
                            description="Link to instructions page"
                            defaultMessage="INSTRUCTIONS"
                          />
                        </div>
                    </Link>
                  </Grid>
                  <Grid style={{padding:"10px"}}>

                    <Link to="/Examples" className={classes.link}>
                        <div
                        className={classes.customFont}
                        style={this.props.currentPage === 'Examples' ? {color:'#1aa2e2'} : {}}
                        >
                          <FormattedMessage
                            description="Link to examples page"
                            defaultMessage="EXAMPLES"
                          />
                        </div>
                    </Link>
                  </Grid>
                  <Grid style={{padding:"10px"}}>
                    <Link to="/Teachings" className={classes.link}>
                        <div
                        className={classes.customFont}
                        style={this.props.currentPage === 'Teachings' ? {color:'#1aa2e2'} : {}}
                        >
                          <FormattedMessage
                            description="Link to teachings page"
                            defaultMessage="TEACHINGS"
                          />
                        </div>
                    </Link>
                  </Grid>
                  {this.props.currentTeaching && 
                  <Grid style={{padding:"10px"}}>
                  <Link to="/Catalog" className={classes.link}>
                      <div
                      className={classes.customFont}
                      style={this.props.currentPage === 'Catalog' ? {color:'#1aa2e2'} : {}}
                      >
                        <FormattedMessage 
                            description="Link to catalog form page"
                            defaultMessage="FORM"
                        />
                      </div>
                  </Link>
                </Grid>                    
                  }
                  {this.props.adminAccess &&
                  <Grid style={{padding:"10px"}}>
                    <Link to="/Admin" className={classes.link}>
                        <div
                        className={classes.customFont}
                        style={this.props.currentPage === 'Admin' ? {color:'#1aa2e2'} : {}}
                        >
                          <FormattedMessage
                            description="Link to admin page"
                            defaultMessage="ADMIN"
                        />
                        </div>
                    </Link>
                  </Grid>
                  }        
              </Grid>
          
          </React.Fragment>
        );
}
}

HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps( state ){
  return{
      currentUser: state.currentUser,
      adminAccess: state.adminAccess,
      currentPage: state.currentPage,
      currentTeaching: state.currentTeaching
  }          
}

export default connect(mapStateToProps, {signOut, setCurrentTeaching})(withStyles(styles)(HeaderBar));