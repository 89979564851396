import {
    SET_CURRENT_TEACHING,
    SET_TEACHING_META,
    SET_CURRENT_USER,
    SIGN_OUT,
    SAVE_AUDIO_PLAYED,
    SET_ADMIN_ACCESS,
    SET_PAGE,
    SET_LANGUAGE
} from '../actions/actionTypes';
import { purgeStoredState } from 'redux-persist';
import {persistConfig} from '../store/persistConfig';

const rootReducer = (state = {}, action) => {
    switch(action.type){
        case SAVE_AUDIO_PLAYED:
            return { ...state, audioPlayed: action.payload};
        case SET_CURRENT_TEACHING:
            return { ...state, currentTeaching: action.payload};
        case SET_TEACHING_META:
            return {...state, teachingMeta: action.payload};
        case SET_CURRENT_USER:
            return { ...state, currentUser: action.payload};
        case SET_ADMIN_ACCESS:
            return  {...state, adminAccess: action.payload};
        case SIGN_OUT:
            purgeStoredState(persistConfig);
            return { state : undefined};
        case SET_PAGE:
            return { ...state, currentPage: action.payload};
        case SET_LANGUAGE:
            return { ...state, currentTranslations: action.payload };
        default:
            return state;
    }
}

export default rootReducer;