import {FormGroup, FormControlLabel, Divider, Checkbox, 
  Button, FormLabel, TextField, Card, Grid, CardContent,
   Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography/Typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import azure from 'azure-storage';
import {withRouter} from 'react-router-dom';
import { createPostMeta, getTeachers, getAreas, getLocations, getTopics } from '../api/api';
import { setCurrentTeaching, saveAudioPlayed, setPage } from '../actions/actions'
import { Link } from 'react-router-dom';
import { withSnackbar} from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import queryString from 'query-string';

const styles = theme => ({
  formHeader: {
    margin:'10px'
  },
  textField: {
    marginLeft: '20px',
    marginTop:'5px',
    marginBottom:'20px',
    width: 200,
  },
  divider:{
    margin: '10px'
  },
  heading:{
    marginBottom:'20px'
  },
  icon:{
    paddingLeft:'3px'
  },
  button:{
    margin: '10px'
  }
});

class CatalogPage extends Component {
  constructor(props){
    super(props);
    
    this.state = {
      cancelDialog: false,
      submitDialog: false,
      resource_text_summary: '',
      resource_text_verses: '',
      additionalTopics: '',
      references: '',
      comments: '',
      seriesNumber: '',
      seriesTotal: '',
      isSeries: false,
      renderTopics: false,
      teachers: [''],
      areas: [''],
      locations: [''],
      topicOptions: [[],[]],
      ...this.props.currentTeaching,
    }
    if (!this.state.topics){
      this.state.topics = [];
    }

    this.submit = this.submit.bind(this);
    
  }

  componentDidMount(){

    this.props.setPage('Catalog');

    var teachersPromise = getTeachers();
    var areasPromise = getAreas();
    var locationsPromise = getLocations();
    var topicsPromise = getTopics();
    Promise.all([teachersPromise, areasPromise, locationsPromise, topicsPromise]).then(results => {
      var teachers = results[0];
      teachers.unshift('');
      var areas = results[1];
      areas.unshift('');
      var locations = results[2];
      locations.unshift('');
      var topics = results[3];
      const half = Math.ceil(topics.length / 2);    
      const firstHalf = topics.splice(0, half);
      const secondHalf = topics.splice(-half);
      var splitTopics = [firstHalf,secondHalf];
      this.setState({
        teachers: teachers,
        areas: areas,
        locations: locations,
        topicOptions: splitTopics
      });
    });

    // Download saved log from file if exists and overwrite metadata in state
    var blobService = azure.createBlobService("DefaultEndpointsProtocol=https;AccountName=biblestudy;AccountKey=xww0LbJfiYcJ6KVbq4SlMcWMzA+OwF8RAoZAFxnwFSxUc/nQ7aVfhKQ8ae3EwECvZVlg4wZfAM9432wy7lI+sw==;EndpointSuffix=core.windows.net");
    var parent = this;
    blobService.getBlobToText("saved", this.state.ID.toString() + '.txt', '', function(error, blobContent, blob){
      if (!error){
        parent.stringToState(blobContent);  
      }else{
        // No file has been saved, so set audio to 0
        parent.props.saveAudioPlayed(0);
      }

      // topics have been loaded, so render checkboxes with default values
      parent.setState({renderTopics: true});
    });
  }       

  toggleDialog(name){
    this.setState(prevState => ({[name]: !prevState[name]}));
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheck = name => event => {
    this.setState({
      [name]: event.target.checked
    });
  };

  handleTopicCheck = name => event => {
    this.setState({
      [name]: event.target.checked,
      topics: this.state.topics.includes(name) ? this.state.topics.filter(n => n !== name) : this.state.topics.concat(name)
    })
  }

  stringToState = function(textFileString){
    var split = textFileString.split(';;;');
      var newState = {};
      for (var prop in split){
        var splitProp = split[prop].split(':::');

        if (splitProp[0] === 'topics'){
          var splitTopics = splitProp[1].substring(1).split('!');
          newState[splitProp[0]] = splitTopics;
        }else if (splitProp[0] === 'isSeries'){
          newState[splitProp[0]] = splitProp[1] === "true"; 
        }else if (splitProp[0] === 'audioPlayed'){
          // Set audio player from where saved
          this.props.saveAudioPlayed(parseFloat(splitProp[1]));
        }else{
          newState[splitProp[0]] = splitProp[1];
        }
  
      }

      this.setState(newState);    
  }

  stateToString = function(){
    var stateString = '';
    for( var propName in this.state){
      // do not include in state
      if (propName === 'submitDialog'
      || propName === 'cancelDialog'
      || propName === 'teachers'
      || propName === 'areas'
      || propName === 'locations'
      || propName === 'topicOptions'){
        continue;
      }
      if (propName === 'topics'){
        stateString += propName + ':::'
        for (var index in this.state[propName]){
          if (this.state[propName][index] !== ""){
            stateString+= '!' + this.state[propName][index];
          }
        }
        stateString+= ';;;';
      }else{
        stateString += propName + ':::' + this.state[propName] + ';;;';
      }
    }

    // Save audio played in file
    stateString+= 'audioPlayed:::' + this.props.audioPlayed + ';;;';

    return stateString;
  
  }
  
  save = () => {
    var stateString = this.stateToString();
    var blobService = azure.createBlobService("DefaultEndpointsProtocol=https;AccountName=biblestudy;AccountKey=xww0LbJfiYcJ6KVbq4SlMcWMzA+OwF8RAoZAFxnwFSxUc/nQ7aVfhKQ8ae3EwECvZVlg4wZfAM9432wy7lI+sw==;EndpointSuffix=core.windows.net");
    blobService.createBlockBlobFromText("saved", this.state.ID.toString() + '.txt', stateString, function(error){

    });
    this.props.enqueueSnackbar('Saved Catalog!', {variant: 'success'});
    this.props.setCurrentTeaching(this.state);
    this.props.history.push('Teachings');
    window.scrollTo(0,0);
  };

  cancel = () => {
    var blobService = azure.createBlobService("DefaultEndpointsProtocol=https;AccountName=biblestudy;AccountKey=xww0LbJfiYcJ6KVbq4SlMcWMzA+OwF8RAoZAFxnwFSxUc/nQ7aVfhKQ8ae3EwECvZVlg4wZfAM9432wy7lI+sw==;EndpointSuffix=core.windows.net");
    blobService.deleteBlobIfExists("saved", this.state.ID.toString() + '.txt', function(error){

    });
    this.props.setCurrentTeaching(null);
    this.props.saveAudioPlayed(0);
  }
  
  submit(e){
    e.preventDefault();
    var stateString = this.stateToString();
    var blobService = azure.createBlobService("DefaultEndpointsProtocol=https;AccountName=biblestudy;AccountKey=xww0LbJfiYcJ6KVbq4SlMcWMzA+OwF8RAoZAFxnwFSxUc/nQ7aVfhKQ8ae3EwECvZVlg4wZfAM9432wy7lI+sw==;EndpointSuffix=core.windows.net");
    // Save log AND submit so if it is unsubmitted, the data will be reloaded
    blobService.createBlockBlobFromText("saved", this.state.ID.toString() + '.txt', stateString, function(error){
    });
    blobService.createBlockBlobFromText("submitted", this.state.ID.toString() + '.txt', stateString, function(error){
    });

    //add done status so it will not show up in available teachings or user's teachings

    const data = {
      "post_id": this.state.ID,
      "meta_key": "log_status",
      "meta_value": "done"
    }
    createPostMeta(data);

    // Remove current teaching so audio bar won't be displayed
    this.props.setCurrentTeaching(null);
    this.props.saveAudioPlayed(0);

    this.props.enqueueSnackbar('Submitted! Thank you!', {variant: 'success'});
    this.props.history.push('Teachings');
    window.scrollTo(0,0);
  };


  render() {
    const { classes } = this.props;

    const {intl} = this.props;
    const messages = defineMessages({
        pageTitle:{
          description: "Catalog page title",
          defaultMessage: "Catalog - Harbor Light Fellowship"
        },
        teachingTitleLabel:{
          description: "Teaching title label",
          defaultMessage: "Teaching Title"
        },
        teachingTeacherLabel:{
          description: "Teaching teacher label",
          defaultMessage: "Teacher"
        },
        teachingDateLabel:{
          description: "Teaching date label",
          defaultMessage: "Teaching Date"
        },
        teachingLocationLabel:{
          description: "Teaching location label",
          defaultMessage: "Location"
        },
        teachingIsPartOfSeriesLabel:{
          description: "Teaching is part of a series checkbox",
          defaultMessage: "This teaching is part of a series"
        },
        seriesNameLabel:{
          description: "Teaching series name label",
          defaultMessage: "Series Name"
        },
        numberInTheSeriesLabel:{
          description: "Teaching number in the series label",
          defaultMessage: "Number in the Series"
        },
        totalNumberInTheSeriesLabel:{
          description: "Total number of teachings in the series label",
          defaultMessage: "Total Number of Teachings in the Series"
        },
        teachingSummaryLabel:{
          description: "Teaching summary label",
          defaultMessage: "Teaching Summary"
        },
        teachingScripturesCoveredLabel:{
          description: "Teaching scriptures covered label",
          defaultMessage: "Scriptures Covered"
        },
        additionalTopicsLabel:{
          description: "Additional topics label",
          defaultMessage: "Additional Topics"
        },
        referencesLabel:{
          description: "References label",
          defaultMessage: "References"
        },
        commentsLabel:{
          description: "Comments label",
          defaultMessage: "Comments"
        },
        teachingSummaryHelperText:{
          description: "Teaching summary helper text",
          defaultMessage: "Please give a brief summary of what this teaching is about to be used as a teaching description in our catalog."
        },
        scripturesCoveredHelperText:{
          description: "Scriptures covered helper text",
          defaultMessage: "Please list, in order, all of the Scriptures the teacher turns to during the teaching (for example: Romans 8:1 [enter] Ephesians 2:6-10 [enter] Romans 10:9 etc.)"
        },
        additionalTopicsHelperText:{
          description: "Additional topics helper text",
          defaultMessage: "If you think that the topic(s) of the teaching is not covered by one of the choices above, please enter any other topics or subjects you think this teaching should be able to be found under, separated by a comma. (i.e. Commitment, Discipline)"
        },
        referencesHelperText:{
          description: "References helper text",
          defaultMessage: "Please mention here if the teacher made reference to a particular book other than the Bible, or a poem, a song, or an event. You could also catalog if there is a notable quote, story or analogy that was memorable."
        },
        commentsHelperText:{
          description: "Comments helper text",
          defaultMessage: "Please use this area to identify anything else you feel we should know but for which there is no specific field to enter"
        },
        topics:{
          description: "List of topics",
          defaultMessage: '[["Believing", "Birth of Jesus Christ", "Dealing with the Adversary", "Death of Jesus Christ", "Healing", "How God\'s Word Interprets Itself", "Integrity of the Word", "Leadership and Service", "Love", "Marriage, Family and Children", "Old Testament Examples", "One God"],["Orientalisms", "Prayer", "Prosperity", "Receiving the holy spirit and the Manifestations", "Renewed Mind", "Sonship Rights", "The First Century Church", "The Great Mystery", "The Hope", "The New Birth", "Witnessing and Undershepherding", "Youth"]]'
        }
    });
    document.title = intl.formatMessage(messages.pageTitle);
    const translatedTopics = JSON.parse(intl.formatMessage(messages.topics));
    const queryValues = queryString.parse(this.props.location.search);
    const isSubmitted = queryValues.submitted;
    
    this.showButtons = !isSubmitted || isSubmitted === 'undefined';
    return (
      <React.Fragment>

        <Dialog open={this.state.cancelDialog}
                onClose={() => this.toggleDialog('cancelDialog')}
        >
          <DialogTitle>
            <FormattedMessage
                description="Header of popup displayed when asking for confirmation to perform an action"
                defaultMessage="Are you sure?"
            />
          </DialogTitle>
          <DialogContent>
              <DialogContentText>
                <FormattedMessage
                  description="Popup message displayed when asking for confirmation to cancel cataloging"
                  defaultMessage="Cancelling this catalog will delete any saved cataloging information.<br/>
                  Are you sure you want to cancel this catalog?"
                />
              </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => this.toggleDialog('cancelDialog')}
            autoFocus>
              <FormattedMessage
                description="Text of button to click when choosing not to cancel cataloging"
                defaultMessage="No, don't cancel!"
              />
          </Button>
          <Link to="/Teachings" style={{textDecoration:'none'}}>
            <Button variant="contained" className={classes.button}
              onClick={() => {
              this.toggleDialog('cancelDialog');
              this.cancel();
            }} color="primary">
              <FormattedMessage
                description="Text of button to click when choosing to cancel cataloging"
                defaultMessage="Yes, I'm sure!"
              />    
            </Button>
          </Link>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.submitDialog}
                onClose={() => this.toggleDialog('submitDialog')}
        >
          <DialogTitle>
          <FormattedMessage
                description="Header of popup displayed when asking for confirmation to perform an action"
                defaultMessage="Are you sure?"
            />
          </DialogTitle>
          <DialogContent>
              <DialogContentText>
              <FormattedMessage
                  description="Popup message displayed when asking for confirmation to submit cataloging"
                  defaultMessage="Submitting means you have completed the catalog and it is ready for review.<br/>
                  You will no longer be able to edit or view this catalog after it is submitted.<br/>
                  Please verify that all of the catalog information is correct before submitting.<br/>
                  Are you sure you want to submit this catalog?"
                />
                  
              </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => this.toggleDialog('submitDialog')}
            autoFocus>
              <FormattedMessage
                description="Text of button to click when choosing not to submit cataloging data"
                defaultMessage="No, do not submit!"
              />    
          </Button>
            <Button variant="contained" className={classes.button} 
              type="submit"
              form="logForm"
              color="primary"             
              onClick={() => {
                this.toggleDialog('submitDialog');
              }}
            >
              <FormattedMessage
                description="Text of button to click when choosing to submit cataloging data"
                defaultMessage="Yes, I'm ready!"
              />  
            </Button>
          </DialogActions>
        </Dialog>
        
        <Grid container justifyContent = "center">
          <Grid item xs={12} md={10}>
            <Card>
              <CardContent >       

                <Typography gutterBottom variant="h4" align="center">
                  <FormattedMessage
                    description="Header of cataloging form"
                    defaultMessage="Harbor Light Media Catalog Form"
                  />
                </Typography>

                <Divider className={classes.divider}/>

                <form autoComplete="off" id="logForm" onSubmit={this.submit}>

                  <Typography variant="body1" color="textSecondary" gutterBottom>
                    <FormattedMessage
                      description="Subtitle of cataloging form"
                      defaultMessage="This form is for you to help us gather the information we need in order to make teachings available and searchable on our website."
                    />
                  </Typography>

                  <Divider className={classes.divider}/>

                  <Typography className={classes.heading} variant="h6" gutterBottom>
                    <FormattedMessage
                      description="Catalog form question to enter teaching title"
                      defaultMessage="Which teaching was this?"
                    />
                  </Typography>

                  <TextField id="title" 
                    label={intl.formatMessage(messages.teachingTitleLabel)}
                    required className={classes.textField}
                    value={this.state.post_title}
                    variant="outlined"
                    multiline
                    onChange={this.handleChange('post_title')}
                  />

                  <TextField className={classes.textField}
                    id="teacher"
                    select
                    label={intl.formatMessage(messages.teachingTeacherLabel)}
                    value={this.state.teacher || ''}
                    onChange={this.handleChange('teacher')}
                    variant="outlined"
                  >
                    {this.state.teachers.map((teacher, i) => (
                      <MenuItem key={i} value={teacher}>
                        {teacher}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField id="date"
                    label={intl.formatMessage(messages.teachingDateLabel)}
                    placeholder="yyyymmdd" 
                    className={classes.textField}
                    value={this.state.date}
                    variant="outlined"
                    onChange={this.handleChange('date')}
                  />

                  <br/>

                  <TextField className={classes.textField}
                    style={{width:'39%'}}
                    id="location"
                    select
                    label={intl.formatMessage(messages.teachingLocationLabel)}
                    value={this.state.location || ''}
                    onChange={this.handleChange('location')}
                    variant="outlined"
                  >
                    {this.state.locations.map((location, i) => (
                      <MenuItem key={i} value={location}>
                        {location}
                      </MenuItem>
                    ))}
                  </TextField>

                  <br/>

                  <FormControlLabel control={
                    <Checkbox
                      checked={this.state.isSeries}
                      onChange={this.handleCheck('isSeries')}
                    />
                    }
                    label={intl.formatMessage(messages.teachingIsPartOfSeriesLabel)}
                  />

                  <br/>

                  {this.state.isSeries ? 
                    <React.Fragment>
                      <TextField id="seriesName"
                        label={intl.formatMessage(messages.seriesNameLabel)}
                        className={classes.textField} style={{width:'60%'}}
                        value={this.state.resource_series_title}
                        variant="outlined"
                        onChange={this.handleChange('resource_series_title')}       
                      />
                      <br/>
                      <TextField id="seriesNumber"
                        label={intl.formatMessage(messages.numberInTheSeriesLabel)}
                        className={classes.textField} style={{width:'20%', minWidth:'100px'}} type="number"
                        value={this.state.seriesNumber}
                        variant="outlined"
                        onChange={this.handleChange('seriesNumber')}         
                        //helperText="Teaching number in the series"
                      />
                      
                      <TextField id="seriesTotal"
                        label={intl.formatMessage(messages.totalNumberInTheSeriesLabel)}
                        className={classes.textField} style={{width:'35%', minWidth:'100px'}} type="number"
                        value={this.state.seriesTotal}
                        variant="outlined"
                        onChange={this.handleChange('seriesTotal')}         
                        //helperText="Total number of teachings in series"
                      />
                    </React.Fragment>
                    : ''
                  }
                  
                  <Divider className={classes.divider}/>

                  <Typography className={classes.heading} variant="h6">
                    <FormattedMessage
                      description="Subtitle of second section of catalog form"
                      defaultMessage="Tell us about the teaching..."
                    />     
                  </Typography>

                  <TextField id="summary"
                    label={intl.formatMessage(messages.teachingSummaryLabel)}
                    className={classes.textField} style={{width:'60%'}} required
                    value={this.state.resource_text_summary}
                    multiline
                    variant="outlined"
                    onChange={this.handleChange('resource_text_summary')}
                    helperText={intl.formatMessage(messages.teachingSummaryHelperText)}   
                  />

                  <TextField id="scriptures"
                    label={intl.formatMessage(messages.teachingScripturesCoveredLabel)}
                    className={classes.textField} style={{width:'60%'}} required
                    value={this.state.resource_text_verses}
                    multiline
                    variant="outlined"
                    onChange={this.handleChange('resource_text_verses')}
                    helperText={intl.formatMessage(messages.scripturesCoveredHelperText)}
                  />

                  <FormGroup className={classes.checkBoxes}>
                    <FormLabel style={{marginTop: '20px'}} component="legend">
                      <FormattedMessage
                        description="Header of topics section of catalog form"
                        defaultMessage="Topics Covered"
                      />     
                      </FormLabel>

                    <Typography variant="body1" color="textSecondary" style={{marginLeft: '10px', marginTop: '10px'}}>
                      <FormattedMessage
                        description="Instructions for choosing topics for a teaching"
                        defaultMessage="Please check the box next to any and all of the following topics that are covered in this teaching"
                      />            
                    </Typography>

                    <Grid container spacing={3}>
                      {translatedTopics.map( (column, i) => 
                        (
                        <Grid item xs={5} key={"column"+i}>
                          {column.map( topic =>
                            <React.Fragment key={topic}>
                            {this.state.renderTopics && <FormControlLabel
                            control={
                              <Checkbox
                              defaultChecked={this.state.topics.includes(topic)}
                              onChange={this.handleTopicCheck(topic)}
                              />
                            }
                            label = {topic}
                            />}
                            <br/>
                            </React.Fragment>
                          )}
                        </Grid>
                        )
                      )}
                    </Grid>
                  </FormGroup>

                  <br />

                  <TextField id="additionalTopics"
                    label={intl.formatMessage(messages.additionalTopicsLabel)}
                    className={classes.textField} style={{width:'60%'}}
                    value={this.state.additionalTopics}
                    variant="outlined"
                    multiline
                    onChange={this.handleChange('additionalTopics')}
                    helperText={intl.formatMessage(messages.additionalTopicsHelperText)}
                    />
                  
                  
                  <br />

                  <Divider className={classes.divider}/>

                  <Typography className={classes.heading} variant="h6" gutterBottom>
                      <FormattedMessage
                        description="Header of additional information section of catalog form"
                        defaultMessage="Additional information..."
                      />   
                    
                  </Typography>

                  <TextField id="references"
                    label={intl.formatMessage(messages.referencesLabel)}
                    className={classes.textField} style={{width:'60%'}}
                    value={this.state.references}
                    variant="outlined"
                    multiline
                    onChange={this.handleChange('references')}
                    helperText={intl.formatMessage(messages.referencesHelperText)}
                  />

                  <br />

                  <TextField id="comments"
                    label={intl.formatMessage(messages.commentsLabel)}
                    className={classes.textField} style={{width:'60%'}}
                    value={this.state.comments}
                    variant="outlined"
                    multiline
                    onChange={this.handleChange('comments')}
                    helperText={intl.formatMessage(messages.commentsHelperText)}
                  />
                  
                  <br/>
                  {this.showButtons && 
                  <Grid container spacing={1} justifyContent="flex-end">
                
                <Grid item>
                    <Button variant="contained" onClick={() => this.toggleDialog('cancelDialog')}>
                      <FormattedMessage
                        description="Cancel button text"
                        defaultMessage="Cancel"
                      />
                      <DeleteIcon className={classes.icon}/>                      
                    </Button>
                  </Grid>
                <Grid item>
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={this.save}>
                      <FormattedMessage
                        description="Save button text"
                        defaultMessage="Save"
                      />
                    <SaveIcon className={classes.icon}/>
                    </Button>
                
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"                                  
                    onClick={() => this.toggleDialog('submitDialog')}>
                      <FormattedMessage
                        description="Submit button text"
                        defaultMessage="Submit"
                      />
                    <SendIcon className={classes.icon}/>
                  </Button>
                </Grid>
                  </Grid>
                  }

                  </form>
                  {this.showButtons &&
                  <Grid container spacing={1}>
                <Grid item>
                <Link to="/Teachings" style={{textDecoration:'none'}}>
                  <Button
                    variant="contained"
                    color="primary"
                    >
                      <FormattedMessage
                        description="Button text to return to list of teachings to catalog"
                        defaultMessage="Back to teaching list"
                      />  
                  </Button>
                </Link>
                </Grid>
                    </Grid>
                }
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentTeaching: state.currentTeaching,
    teachingMeta: state.teachingMeta,
    audioPlayed: state.audioPlayed
  }
}

export default connect(mapStateToProps, { setCurrentTeaching, saveAudioPlayed, setPage })(withSnackbar(withStyles(styles)(withRouter(injectIntl(CatalogPage)))));
