var basePath = process.env.REACT_APP_CATALOG_API_SITE_BASE;
console.log(`using api at: ${process.env.REACT_APP_CATALOG_API_SITE_BASE}`);

const makeRequest = (path = '', method = 'GET', data = {}) => {
    const init = {
        method,
        ...(/GET|HEAD/.test(method) ? {} : {body: JSON.stringify(data)}),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin' : '*'
        }),
    };

    return fetch(`${basePath}${path}`, init)
        .then(response => response.text())
        .then(JSON.parse);
};

const makePutRequest = async (path = '', method = 'PUT', id = -1, data = {}) => {
    const init = {
        method,
        ...(/GET|HEAD/.test(method) ? {} : {body: JSON.stringify(data)}),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin' : '*'
        }),
    };

    const response = await fetch(`${basePath}${path}/${id}`, init);
    const textResponse = await response.text();
    if (textResponse){
        return JSON.parse(textResponse);
    }
}

const makeDeleteRequest = async (path = '', method = 'DELETE', id = -1) => {
    const init = {
        method
    };

    const response = await fetch(`${basePath}${path}/${id}`, init);
    const textResponse = await response.text();
    if (textResponse) {
        return JSON.parse(textResponse);
    }
}

export const createPostMeta = (data) => {
    return makeRequest(`postmeta`, 'POST', data);
}

export const deletePostMeta = async (id) => {
    return makeDeleteRequest(`postmeta`, 'DELETE', id);
}

export const updatePostMeta = (id, data) => {
    return makePutRequest(`postmeta`, 'PUT', id, data);
}

export const getChosenTeachingsByUser = (userId) => {
    return makeRequest(`teachings/chosen/user/${userId}`);
}

export const getAvailableTeachingsByUser = (userId, isAdmin) => {
    return makeRequest(`teachings/available/user/${userId}?isAdmin=${isAdmin}`);
}

export const getChosenTeachings = () => {
    return makeRequest(`teachings/chosen`);
}

export const getSubmittedTeachings = () => {
    return makeRequest(`teachings/submitted`);
}

export const getUserById = (userId) => {
    return makeRequest(`userById/${userId}`);
}

export const getUserByUsername = (username) => {
    return makeRequest(`userByUsername/${username}`);
}

export const getIsUserAdmin = (userId) => {
    return makeRequest(`isUserAdmin/${userId}`);
}

export const getIsUserCataloger = (userId) => {
    return makeRequest(`isUserCataloger/${userId}`);
}

export const getTeachers = () => {
    return makeRequest(`teachers`);
}

export const getLocations = () => {
    return makeRequest(`locations`);
}

export const getAreas = () => {
    return makeRequest(`areas`);
}

export const getTopics = () => {
    return makeRequest(`topics`);
}