import { createStore} from 'redux';
import rootReducer from '../reducers/rootReducer';
import {persistStore, persistReducer} from 'redux-persist';
import {persistConfig} from './persistConfig';

export default function configureStore(){

    const persistedReducer = persistReducer(persistConfig, rootReducer);


    let store = createStore(persistedReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    let persistor = persistStore(store);

    return {store, persistor};
};
