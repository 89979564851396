const crypto = require('crypto');
const cryptoJS = require('crypto-js');
const { ord, strpos } = require('locutus/php/strings');

const itoa64 = './0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

const encode64 = function(input, count) {
    let output = '';
    let i = 0, value;
    let v;

    do {
        value = ord(input.charAt(i++));
        v = value & 0x3F;
        output = `${output}${itoa64.charAt(v)}`;
        if (i < count) {
            value |= ord(input.charAt(i)) << 8;
        }
        v = (value >> 6) & 0x3F;
        output = `${output}${itoa64.charAt(v)}`;
        if (i++ >= count) {
            break;
        }
        if (i < count) {
            value |= ord(input.charAt(i)) << 16;
        }
        v = (value >> 12) & 0x3F;
        output = `${output}${itoa64.charAt(v)}`;
        if (i++ >= count) {
            break;
        }
        v = (value >> 18) & 0x3F;
        output = `${output}${itoa64.charAt(v)}`;
    } while (i < count);

    return output;
}

const crypt_private = function(password, setting) {
    let output = '*0';

    if (setting.substr(0, 2) === output)
        output = '*1';

    const id = setting.substr(0, 3);
    // We use "$P$", phpBB3 uses "$H$" for the same thing
    if (id !== '$P$' && id !== '$H$')
        return output;

    const count_log2 = strpos(itoa64, setting.charAt(3));
    if (count_log2 < 7 || count_log2 > 30)
        return output;

    let count = 1 << count_log2;

    const salt = setting.substr(4, 8);
    if (salt.length !== 8) {
        return output;
    }

    let hash = crypto.createHash('md5').update(`${salt}${password}`, 'binary').digest('binary');
    do {
        hash = crypto.createHash('md5').update(`${hash}${password}`, 'binary').digest('binary');
    } while (--count);
    output = setting.substr(0, 12);
    output = `${output}${encode64(hash, 16)}`;

    return output;
}

/**
 * @see https://github.com/glauberportella/password-hash
 */

const checkPassword = function(password, stored_hash){
    if (password.length > 4096) {
        return false;
    }

    let hash = crypt_private(password, stored_hash);
    if (hash.charAt(0) === '*'){
        hash = cryptoJS.TripleDES.encrypt(password, stored_hash);
    }

    return hash === stored_hash;
}

function teachingSorter(a,b){
    var compare1 = a.resource_series_title;
    var compare2 = b.resource_series_title;

    // if one doesn't have a series, it goes before
    if (!compare1 && compare2){
        return -1;
    }
    if (compare1 && !compare2){
        return 1;
    }

    // if both dont have series, compare by title
    if (!compare1 && !compare2){
        compare1 = a.post_title;
        compare2 = b.post_title;
    }
    
    // Both have series, or both don't so compare by like
    if (compare1 < compare2){
        return -1;
    }
    if (compare1 > compare2){
        return 1;
    }

    // Title was the same, and no series number, they are equal
    if (!a.seriesNumber || !b.seriesNumber){
        return 0;
    }

    // If same series, sort by number
    var num1 = parseInt(a.seriesNumber, 10);
    var num2 = parseInt(b.seriesNumber, 10);
    if (num1 < num2){
        return -1;
    }
    if (num1 > num2){
        return 1;
    }

    return 0;
}

function decryptUserId(encryptedUserId){
    var userId = encryptedUserId + "";
    var key = 13;
    userId = userId.split('').map(function(c){return c.charCodeAt(0);}).map(function(i){return i ^ key;});
    userId = String.fromCharCode.apply(undefined, userId);
    return userId;
}

export {teachingSorter, decryptUserId, checkPassword};