import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setPage, saveAudioPlayed, setCurrentTeaching } from '../actions/actions'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

const styles = theme => ({
    divider:{
      margin: '10px'
    },
    button:{
      margin:'10px',
      float:'right'
    }
  });

class InstructionsPage extends Component {
    componentDidMount(){
        this.props.setPage('Instructions');
        this.props.setCurrentTeaching(null);
        this.props.saveAudioPlayed(undefined);
    }
    render(){
        const {classes} = this.props;
        const {intl} = this.props;
        const messages = defineMessages({
            pageTitle:{
              description: "Instructions page title",
              defaultMessage: "Intructions - Harbor Light Fellowship"
            }
        });
        document.title = intl.formatMessage(messages.pageTitle);
        return (
            <React.Fragment>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={10}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6" align="center">
                                    <FormattedMessage
                                        description="Instructions page heading"
                                        defaultMessage="Harbor Light Fellowship Recorded Teaching Cataloging Project"
                                    />
                                </Typography>

                                <Divider className={classes.divider}/>
                                <Typography component={'span'} variant="body1" color="textSecondary" gutterBottom>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="Thank you for helping with Harbor Light Fellowship's recorded teaching cataloging project. The idea is to record certain types of information about each teaching that we can then use when we upload it to our website, making it searchable there for people to access and enjoy."
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Step 1: </b>Click TEACHINGS in the navigation bar above to browse teachings available for cataloging."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Step 2: </b>In the 'Available Teachings' section, click the blue plus button next to a teaching you wish to catalog. This will assign the teaching to you and remove it from the available teachings list."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Step 3: </b>In the 'Your Teachings' section, click the green catalog button to start listening to the teaching you have chosen."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Step 4: </b>As you listen to the teaching, fill out the catalog form with the following information.<br></br><i>Note: Much of the information may already be pre-filled. Please review pre-filled fields, and modify them if necessary.</i>"
                                    values={{
                                        b: chunk => <b>{chunk}</b>,
                                        i: chunk => <i>{chunk}</i>,
                                        br: () => <br/>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Teaching Title: </b>Record the title of the teaching.  As much as possible, use the “official” title of the teaching. That could be the title on the recording itself, its name in The Way International Bookstore catalog (if applicable), the name given on a CD set or on the Harbor Light Fellowship bookstore price list, etc."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Teacher: </b>Choose the name of the teacher from the dropdown."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Teaching Date: </b>Record the date, if you know it, using the format yyyymmdd. For example, May 13, 2019 would be entered as 20190513. If you only know the partial date, enter as much as you know. For example, the month and year, May 2019, would be entered as 201905, and just the year 2019 would be entered as 2019."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                /> 
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Location: </b>If you know the location that the teaching took place, please choose the location from the dropdown."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>This teaching is part of a series: </b>Click this checkbox if the teaching is part of a series, such as an advance or a Sunday Night Service. A checkmark will appear in the box."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                /> 
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Series Name: </b>Record the name of the series, if you know it."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                /> 
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Number in the series: </b>Record which number this teaching is in the series, if you know it."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Total Number of Teachings in the Series: </b>Record the total number of teachings in the series, if you know it."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />  
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Teaching Summary: </b>Write a short summary of the teaching that could be used as a description in our catalog to give a person searching for a teaching an idea of what the teaching covers. It should be a short paragraph that outlines the major sections of the teaching. It should not just be a list of points made in the teaching."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                /> 
                                
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Scriptures Covered: </b> List the scriptures that are covered in the teaching in the order that they are covered. After you enter the verse reference or range of verses, press the Enter key on your keyboard to start a new line."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <ul>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<li>Include all scripture references that the teacher tells the audience to turn to, all scriptures that the teacher turns to even if the teacher tells the audience not to turn to them, and all scriptures that the teacher mentions and gives the reference.</li>
                                    <li>If the teacher goes to a section, include all verses that they read from in that section even if they only read a part of the verse and even if they do not give the reference for that specific verse.</li>
                                    <li>Do not include scriptures that are only quoted in passing without giving a reference. Example: if the teacher just says “Christ in you, the hope of glory,” you do not need to include Colossians 1:27 in the scripture list.</li>
                                    <li>Please use Arabic numerals rather than Roman numerals in the name s of books of the Bible.  Ex. 1 Kings 1:5</li>
                                    <li>You do not need to indicate that only part of the verse was read. You can give the normal reference.</li>
                                    <li>A scripture reference should be included each time it is gone to as long as at least one other verse has been gone to between instances.</li>
                                    <li>For scripture references in the Book of Psalms, please include the 's' at the end of Psalms.  Example: Psalms 119:11</li>
                                    <li>Where the teacher reads a series of consecutive verses, you can simply give the range of verses covered. Please use a dash between the verses even if there are only two consecutive verses. Example: 1 Corinthians 12:1-7, Romans 10:9-10</li>
                                    <li>Where the teacher reads consecutive verses but crosses into a new chapter in that book of the Bible, please list the verses by chapter. For example, if the teacher covers Ephesians 4:22 through Ephesians 5:2, you would enter it as two separate references, Ephesians 4:22-32 and Ephesians 5:1-2.</li>
                                    <li>If the teacher reads a series of verses but skips some, please make a new entry for each set of consecutive verses or individual verses. Example: If the teacher reads Romans Chapter 5, verses 12-14, 18, and 21, you would enter:</li>"
                                    values={{
                                        li: chunk => <li>{chunk}</li>
                                    }}
                                />
                                </ul>
                                <span style={{marginLeft: '50px'}}>
                                    <FormattedMessage
                                        description="Cataloging instructions"
                                        defaultMessage="Romans 5:12-14"
                                    />
                                </span>
                                <br/>
                                <span style={{marginLeft: '50px'}}>
                                    <FormattedMessage
                                        description="Cataloging instructions"
                                        defaultMessage="Romans 5:18"
                                    />
                                </span>
                                <br/>
                                <span style={{marginLeft: '50px'}}>
                                    <FormattedMessage
                                        description="Cataloging instructions"
                                        defaultMessage="Romans 5:21"
                                    />
                                </span>
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Topics Covered: </b>Topics are used to help someone quickly find a targeted list of teachings on a specific major topic. Some teachings cover several of our pre-defined topics. Please do <b>not</b> identify <b>every</b> topic that is mentioned in the teaching. When deciding to assign a topic, please ask yourself if this is a teaching you would like to be displayed if you were searching for teachings on this particular topic. Generally, there will be 1 to 3 total topics and additional topics combined for a teaching. Click in the box next to the topics you want assigned. A checkmark will display in the box."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Additional Topics: </b>Enter here any main topics or subjects that you think the teaching falls under but they are not included in the pre-defined list. Please separate these with commas. Again, only enter a topic that is a main focus of the teaching."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>References: </b>Mention here if the teacher made reference to a particular book other than the Bible, or a poem, a song, or an event.  You could also catalog if there is a notable quote, story or analogy that was memorable."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Comments: </b>Please use this area to identify anything else you feel we should know but for which there is no specific field to enter.  Perhaps you ran into a section of scripture that you were not sure how to catalog, you can mention that here to have it reviewed before the teaching's information is added to the website."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Cancel Button: </b> This button will delete any saved cataloging information for this teaching. Any information that was pre-filled will still remain."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Save Button: </b>Clicking this button will save your cataloging information and your spot in the teaching so you can return to it later. When you return to the cataloging form, the teaching will play from where you left off."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Submit Button: </b>Once you are done completing the form for the teaching, click on the “Submit” button at the bottom of the form to send us your data."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="If you have any questions regarding how to fill out the form or have any suggestions on how to improve the form or the process, please contact Dan Chatten at 201-419-0318 or dfcj13@verizon.net."
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                                            
                                <br/>
                                <br/>
                                <FormattedMessage
                                    description="Cataloging instructions"
                                    defaultMessage="<b>Thanks for your help on this project that we trust will help us bless many people in the future via the website! God bless you.</b>"
                                    values={{
                                        b: chunk => <b>{chunk}</b>
                                    }}
                                />
                                
                                </Typography>
                            </CardContent>
                            <Link to="/Teachings" style={{textDecoration:'none'}}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() =>{
                                        window.scrollTo(0,0);
                                    }
                                }>
                                    <FormattedMessage
                                        description="Start cataloging button"
                                        defaultMessage="Start Cataloging"
                                    />
                                </Button>
                            </Link>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

export default connect(null, {setPage, setCurrentTeaching, saveAudioPlayed})(withStyles(styles)(injectIntl(InstructionsPage)));