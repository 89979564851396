import React, { Component } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { setCurrentTeaching, setTeachingMeta, saveAudioPlayed, setPage } from '../actions/actions'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import LogIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import HelpIcon from '@mui/icons-material/Info';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {createPostMeta, deletePostMeta, getChosenTeachingsByUser, getAvailableTeachingsByUser } from '../api/api';
import {teachingSorter} from '../utils';
import Loader from 'react-loader-spinner';
import azure from 'azure-storage';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

const styles = theme =>  ({
    button:{
        margin: '10px'
    },
    links:{
        textDecoration: 'none'
    },
    teachingText:{
        fontSize:'12pt',
        [theme.breakpoints.down('sm')]:{
            fontSize:'11pt',
          },
    },
    seriesText:{
        fontSize:'10pt',
        fontStyle:'italic',
        paddingLeft:'10px',
        [theme.breakpoints.down('sm')]:{
            fontSize:'10pt',
          },
    },
    catalogIconBig:{
        [theme.breakpoints.down('sm')]:{
            display:'none',
          },
    },
    catalogIconSmall:{
        [theme.breakpoints.up('sm')]:{
            display:'none'
        }
    }
});


const TeachingItem =  (props) => (
    <TableRow key = {props.teaching.ID}>
        <TableCell padding="none" style={{textAlign:'center'}}>
        {props.choose ? 
            <Fab
                variant="circular"
                size="small"
                color="primary"
                style={{marginTop: '5px', marginBottom: '5px'}}
                onClick={ () => {
                    window.scrollTo(0,0);
                    props.chooseTeaching(props.teaching);
                }
            }>
                <AddIcon />
            </Fab>
            :
            <Fab
                variant="circular"
                size="small"
                style={{marginTop: '10px', marginBottom: '10px'}}
                onClick={ () => {
                    props.removeTeaching(props.teaching);
                }
            }>
                <RemoveIcon />
            </Fab>
        }
        </TableCell>
        <TableCell padding="none" style={{padding:'10px 10px 10px', paddingLeft:'0px'}}>
            <div className={props.style.teachingText}>{props.teaching.post_title}</div>
            {props.teaching.isSeries && <div className={props.style.seriesText}>{props.teaching.resource_series_title + (props.teaching.seriesNumber ? ': #' + props.teaching.seriesNumber : '')}</div>}
        </TableCell>
        <TableCell padding="none" style={{paddingRight:'10px'}}>
            <div className={props.style.teachingText}>{props.teaching.teacher}</div>
        </TableCell>
        <TableCell padding="none" style={{textAlign:'center'}}>
            {!props.choose &&
            <React.Fragment>                      
            <div className={props.style.catalogIconBig}>                
                <Link to="/Catalog" style={{textDecoration:'none'}}>
                    <Fab
                    variant="extended"
                    size="small"
                    color="secondary" 
                        onClick={ () => {
                            window.scrollTo(0,0);
                            if (!props.currentTeaching || props.currentTeaching.ID !== props.teaching.ID){
                                props.setCurrentTeaching(props.teaching);
                            }
                        }
                    }>
                        <LogIcon/>
                        <FormattedMessage
                            description="Button to begin cataloging a teaching"
                            defaultMessage="Catalog"
                        />
                    </Fab> 
                </Link>
            </div>
            <div className={props.style.catalogIconSmall}>                
            <Link to="/Catalog" style={{textDecoration:'none'}}>
                <Fab
                variant="circular"
                size="small"
                color="secondary" 
                    onClick={ () => {
                        window.scrollTo(0,0);
                        if (!props.currentTeaching || props.currentTeaching.ID !== props.teaching.ID){
                            props.setCurrentTeaching(props.teaching);
                        }
                    }
                }>
                    <LogIcon/>
                </Fab> 
            </Link>
        </div>
        </React.Fragment>   
            }
            
        </TableCell>
    </TableRow>
);

class TeachingsPage extends Component {

    constructor(){
        super();
        this.state = {
            availableTeachings: [ ],
            userTeachings: [ ],
            yourTeachingsDialog : false,
            availableTeachingsDialog : false
        }
        this.chooseTeaching = this.chooseTeaching.bind(this);
        this.removeTeaching = this.removeTeaching.bind(this);
    }

    toggleDialog(name){
        this.setState(prevState => ({[name]: !prevState[name]}));
    }

    chooseTeaching(teaching){
        this.setState({loading:true});

        createPostMeta({
            "post_id": teaching.ID,
            "meta_key": "logger_id",
            "meta_value": this.props.currentUser.id.toString()
        }).then(meta => {
            // Manually set these since they wouldn't be applied until refresh
            teaching.loggerMetaId = meta.meta_id;
            teaching.loggerId = meta.meta_value;
            this.setState({
                userTeachings: this.state.userTeachings.concat(teaching),
                availableTeachings: this.state.availableTeachings.filter(e => e !== teaching),
                loading:false
            });
        });
    }

    removeTeaching(teaching){
        this.setState({loading:true});

        var userTeachings = this.state.userTeachings.filter(e => e !== teaching);
        var availableTeachings = this.state.availableTeachings.concat(teaching).sort(teachingSorter);

        this.setState({
            loading:false,
            userTeachings: userTeachings,
            availableTeachings: availableTeachings
        });
        if (this.props.currentTeaching && this.props.currentTeaching.ID === teaching.ID){
            this.props.setCurrentTeaching(null);
            this.props.saveAudioPlayed(null);
        }

        deletePostMeta(teaching.loggerMetaId).then(() => {
            teaching.loggerMetaId = null;
            teaching.loggerId = null;

            var blobService = azure.createBlobService("DefaultEndpointsProtocol=https;AccountName=biblestudy;AccountKey=xww0LbJfiYcJ6KVbq4SlMcWMzA+OwF8RAoZAFxnwFSxUc/nQ7aVfhKQ8ae3EwECvZVlg4wZfAM9432wy7lI+sw==;EndpointSuffix=core.windows.net");
            blobService.deleteBlobIfExists("saved", teaching.ID.toString() + '.txt', function(error){ return; });
        });
    }

    
    componentDidMount(){
        this.getTeachings();
        this.props.setCurrentTeaching(null);
        this.props.saveAudioPlayed(undefined);
        this.props.setPage('Teachings');
    }
    
    getTeachings(){
        this.setState({loading:true});

        var chosenPromise = getChosenTeachingsByUser(this.props.currentUser.id);
        var availablePromise = getAvailableTeachingsByUser(this.props.currentUser.id, this.props.adminAccess);
        Promise.all([chosenPromise,availablePromise]).then(results => {
            var chosenTeachings = results[0];
            var availableTeachings = results[1].sort(teachingSorter);
            this.setState({
                userTeachings: chosenTeachings,
                availableTeachings:availableTeachings,
                loading:false
            });
        });
            
    }

  render() {
    const { classes } = this.props;
    const { language } = this.props;

    const availableTeachingItems = this.state.availableTeachings.map((teaching, i) => {

        // Filter teachings by current language. If teaching has no language set, assume it is english
        if (teaching.language === language || (!teaching.language && language === 'en')){
            return (
                <TeachingItem
                    key={teaching.ID}
                    choose={true}
                    teaching={teaching} 
                    setCurrentTeaching={this.props.setCurrentTeaching}
                    chooseTeaching={this.chooseTeaching}
                    style={classes}
                />
            )
        }
        return null;
    });

    const userTeachingItems = this.state.userTeachings.map((teaching, i) => {
        return (
            <TeachingItem
                key={teaching.ID}
                teaching={teaching}
                setCurrentTeaching={this.props.setCurrentTeaching}
                getTeachingMeta={this.getTeachingMeta}
                removeTeaching={this.removeTeaching}
                currentTeaching={this.props.currentTeaching}
                saveAudioPlayed={this.props.saveAudioPlayed}
                style={classes}
            />
        )
    });
    const {intl} = this.props;
    const messages = defineMessages({
        pageTitle:{
            description: "Teachings page title",
            defaultMessage: "Teachings - Harbor Light Fellowship"
        }
    });
    document.title = intl.formatMessage(messages.pageTitle);
    return (
        <React.Fragment>
            <Dialog open={this.state.yourTeachingsDialog}
                onClose={() => this.toggleDialog('yourTeachingsDialog')}
            >
                <DialogTitle>
                    <FormattedMessage
                        description="Header of the section for the user's chosen teachings"
                        defaultMessage="Your Teachings"
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage
                            description="Helper text in the dialog for the user's chosen teachings"
                            defaultMessage="Teachings that you have chosen to catalog will appear here."
                        /><br/>
                        Select the <Fab style={{margin:"3px"}} variant="circular" size="small"><RemoveIcon /></Fab> icon to remove the teaching from your list and make it available for others to catalog.<br/>
                        Select the <Fab style={{margin:"3px"}} variant="extended" color="secondary" size="small"><LogIcon />Log</Fab> icon to start or continue cataloging the teaching.<br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className={classes.button} onClick={() => this.toggleDialog('yourTeachingsDialog')} color="primary" autoFocus>
                        <FormattedMessage
                            description="Ok button"
                            defaultMessage="OK"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.availableTeachingsDialog}
                onClose={() => this.toggleDialog('availableTeachingsDialog')}
                >
                <DialogTitle>
                    <FormattedMessage
                        description="Header of the section for the teachings available to catalog"
                        defaultMessage="Available Teachings"
                    />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormattedMessage
                            description="Helper text in the dialog for the teachings available to catalog"
                            defaultMessage="Teachings available for cataloging will appear here. After choosing a teaching, it will appear in the 'Your Teachings' section above."
                        /><br/>
                        Click the <Fab style={{margin:"3px"}} color="primary" variant="circular" size="small"><AddIcon /></Fab> icon to assign a teaching to yourself.<br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" className={classes.button} onClick={() => this.toggleDialog('availableTeachingsDialog')} color="primary" autoFocus>
                        <FormattedMessage
                            description="Ok button"
                            defaultMessage="OK"
                        />
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container justifyContent = "center">
                <Grid item xs={12} md={10}>                   
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography gutterBottom variant="h5">
                                <FormattedMessage
                                    description="Header of the section for the user's chosen teachings"
                                    defaultMessage="Your Teachings"
                                />
                                <Fab
                                    style={{margin: '10px'}}
                                    variant="extended"
                                    size="small"
                                    onClick={() => this.toggleDialog('yourTeachingsDialog')}
                                    >
                                    <HelpIcon style={{paddingRight:'3px'}}/>
                                    <FormattedMessage
                                        description="Button to click to get help or more information"
                                        defaultMessage="Need Help?"
                                    />
                                </Fab>
                            </Typography>
                            {userTeachingItems.length >= 1 ?
                            
                            <React.Fragment>
                                <Typography gutterBottom variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Subtitle for the 'your teachings' section"
                                        defaultMessage="Listen to a teaching you have chosen."
                                    />
                                </Typography> 
                                <Table >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="none" style={{textAlign:'center', paddingRight:'10px'}}>
                                                <Typography variant="subtitle1" gutterBottom style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Header of the column to remove a teaching"
                                                        defaultMessage="Remove"
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell padding="none" style={{paddingRight:'3px'}}>
                                                <Typography variant="subtitle1" gutterBottom style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Header of the column for the teaching title"
                                                        defaultMessage="Teaching"
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell padding="none" style={{paddingRight:'3px'}}>
                                                <Typography variant="subtitle1" gutterBottom style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Header of the column for the teaching teacher"
                                                        defaultMessage="Teacher"
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell padding="none" style={{textAlign:'center'}}>
                                                <Typography variant="subtitle1" gutterBottom style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Header of the column for the button to start cataloging"
                                                        defaultMessage="Start Cataloging"
                                                    />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {userTeachingItems}               
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                            
                            : this.state.loading ?
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Loader type="Oval" color="black" height="40" width="40"/>
                                <Typography gutterBottom style={{padding:'10px'}} variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Text to display when loading teachings"
                                        defaultMessage="Loading teachings..."
                                    />
                                </Typography>
                            </div>
                            :
                            <Typography gutterBottom variant="body1" color="textSecondary">
                                <FormattedMessage
                                    description="Text to display when no teachings have been chosen to catalog yet"
                                    defaultMessage="You have not chosen any teachings yet. Click on a plus sign to below to assign a teaching to yourself."
                                />
                                
                            </Typography>
                            
                            }
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography gutterBottom variant="h5">
                                <FormattedMessage
                                    description="Header of the section for the teachings available to catalog"
                                    defaultMessage="Available Teachings"
                                />
                                <Fab
                                    style={{margin: '10px'}}
                                    variant="extended"
                                    size="small"
                                    onClick={() => this.toggleDialog('availableTeachingsDialog')}
                                    >
                                    <HelpIcon style={{paddingRight:'3px'}}/>
                                    <FormattedMessage
                                        description="Button to click to get help or more information"
                                        defaultMessage="Need Help?"
                                    />
                                </Fab>
                            </Typography>
                            {availableTeachingItems.length >= 1 ?
                            
                            <React.Fragment>                         
                                <Typography gutterBottom variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Subtitle for the 'available teachings' section"
                                        defaultMessage="Click on the plus sign to choose a teaching to listen to and catalog."
                                    />
                                </Typography> 
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell padding="none" style={{ textAlign:'center',  paddingRight:'10px'}}>
                                                <Typography variant="subtitle1" gutterBottom style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Header of the column to choose a teaching to catalog"
                                                        defaultMessage="Choose"
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell padding="none">
                                                <Typography variant="subtitle1" gutterBottom style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Header of the column for the teaching title"
                                                        defaultMessage="Teaching"
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell padding="none" style={{paddingRight:'5px'}}>
                                                <Typography variant="subtitle1" gutterBottom style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Header of the column for the teaching teacher"
                                                        defaultMessage="Teacher"
                                                    />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {availableTeachingItems}               
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                           : this.state.loading ? 
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Loader type="Oval" color="black" height="40" width="40"/>
                                <Typography gutterBottom style={{padding:'10px'}} variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Text to display when loading teachings"
                                        defaultMessage="Loading teachings..."
                                    />
                                </Typography>
                            </div>
                           :
                            <Typography gutterBottom variant="body1" color="textSecondary">
                                <FormattedMessage
                                    description="Text to display when no teachings are available to catalog"
                                    defaultMessage="No teachings are available to catalog at this time."
                                />          
                            </Typography>  
                            
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
  }
}

function mapStateToProps( state ){

    return{
        currentUser: state.currentUser,
        currentTeaching: state.currentTeaching,
        adminAccess: state.adminAccess,
        language: state.currentTranslations ? state.currentTranslations.locale : 'en'
    }          
    
}

export default connect(mapStateToProps, { setCurrentTeaching, setTeachingMeta, saveAudioPlayed, setPage})(withStyles(styles)(injectIntl(TeachingsPage)));
