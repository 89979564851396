import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { blue, green } from '@mui/material/colors';

export default createTheme(adaptV4Theme({
    typography:{
        fontFamily: [
            '"Open Sans"',
            'Arial',
            'Helvetica',
            'sans-serif'
        ].join(','),
    },
    shadows: Array(25).fill('none'),
    palette: {
        primary: blue,
        secondary: green,
    },
    appBar: {
        height:20,
    }

}));