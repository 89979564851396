import React, { Component } from 'react';
import {FormGroup, FormControlLabel, Divider, Checkbox, FormLabel, TextField, Card, Grid, CardContent, MenuItem} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography/Typography';
import ReactAudioPlayer from 'react-audio-player';
import { connect } from 'react-redux';
import { setPage, saveAudioPlayed, setCurrentTeaching } from '../actions/actions'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

const styles = theme => ({
    formHeader: {
        margin:'10px'
      },
      textField: {
        marginLeft: '20px',
        marginTop:'5px',
        marginBottom:'20px',
        width: 200,
      },
      divider:{
        margin: '10px'
      },
      heading:{
        marginBottom:'20px'
      },
      icon:{
        paddingLeft:'3px'
      },
      button:{
        margin: '10px'
      }
  });

class ExamplesPage extends Component {

  constructor(props){
    super(props);

  }
    componentDidMount(){
        this.props.setPage('Examples');
        this.props.setCurrentTeaching(null);
        this.props.saveAudioPlayed(undefined);

    }
    render(){
        const {classes} = this.props;
        const {intl} = this.props;
        const {language} = this.props;
        const messages = defineMessages({
          pageTitle:{
            description: "Examples page title",
            defaultMessage: "Examples - Harbor Light Fellowship"
          },
          topics:{
            description: "List of topics",
            defaultMessage: '[["Believing", "Birth of Jesus Christ", "Dealing with the Adversary", "Death of Jesus Christ", "Healing", "How God\'s Word Interprets Itself", "Integrity of the Word", "Leadership and Service", "Love", "Marriage, Family and Children", "Old Testament Examples", "One God"],["Orientalisms", "Prayer", "Prosperity", "Receiving the holy spirit and the Manifestations", "Renewed Mind", "Sonship Rights", "The First Century Church", "The Great Mystery", "The Hope", "The New Birth", "Witnessing and Undershepherding", "Youth"]]'
          }
        });
        document.title = intl.formatMessage(messages.pageTitle);
        const translatedTopics = JSON.parse(intl.formatMessage(messages.topics));

        return (
          <React.Fragment>
              <Grid container justifyContent="center">
                  <Grid item xs={12} md={10}>
                      <Card>
                          <CardContent>
                            <Typography variant="h6" align="center">
                              <FormattedMessage
                                  description="Examples page header"
                                  defaultMessage="Examples of Completed Cataloging Forms"
                              />
                              
                            </Typography>
                            <Divider className={classes.divider}/>
                              <Typography component={'span'} variant="body1" color="textSecondary" gutterBottom>
                                <FormattedMessage
                                    description="Examples page subheader"
                                    defaultMessage="Below are two examples of filled out forms for teachings that have been cataloged. These should help you to get a better understanding of what we are asking for in some of the sections. The audio of the teaching logged is provided before the example form if you would like to listen to it yourself."
                                />
                              </Typography>
                              <br/>
                              <br/>

                {!language || !language.locale || language.locale === 'en' ?
                <React.Fragment>
                <Divider className={classes.divider}/>
                <Typography className={classes.heading} variant="h6" gutterBottom>
                  Example 1
                </Typography>
                <Divider className={classes.divider}/>
                <form autoComplete="off">
                <Grid container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  className={classes.root}>
                  <Grid style={{padding:"10px"}}>
                      <Typography variant="body1" color="textSecondary">
                          How Jesus Christ Prepared Us to Walk in the Spirit                               
                      </Typography>
                  </Grid>
                  <Grid>
                    <ReactAudioPlayer
                        src="https://cdn.harborlightfellowship.com/wp-content/uploads/2020/04/10114323/2011-Spring-Advance-6-How-Jesus-Christ-Prepared-Us-to-Walk-in-the-Spirit-Michael-Gilmore.mp3"
                        controls={true}
                        listenInterval={1000}
                        />
                  </Grid>        
              </Grid>

                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6" gutterBottom>
                  Which teaching was this?
                </Typography>

                <TextField id="title" label="Teaching Title" required className={classes.textField}
                  value="How Jesus Christ Prepared Us to Walk in the Spirit"
                  variant="outlined"
                  multiline
                  />

                <TextField className={classes.textField} 
                  id="teacher"
                  select
                  label="Teacher"
                  value="Gilmore, Michael"
                  variant="outlined"
                  >
                  <MenuItem value="Gilmore, Michael">
                      Gilmore, Michael
                  </MenuItem>
                </TextField>

                <TextField id="date" 
                  label="Teaching Date"
                  className={classes.textField}
                  value="20110501"
                  variant="outlined"
                  />

                <br/>

                <TextField className={classes.textField} 
                  style={{width:'39%'}}
                  id="location"
                  select
                  label="Location"
                  value="Greenkill Conference Center"
                  variant="outlined"
                  >
                  <MenuItem value="Greenkill Conference Center">
                      Greenkill Conference Center
                  </MenuItem>
                </TextField>

                <br/>

                <FormControlLabel control={
                  <Checkbox checked={true} />
                }
                label = "This teaching is part of a series"
                />

                <br/>
              
                  <TextField id="seriesName" label="Series Name" className={classes.textField} style={{width:'60%'}} 
                  value="2011 Spring Advance - Jesus Christ: The Subject of the Written Word"
                  variant="outlined"       
                  />
                  <br/>
                  <TextField id="seriesNumber" label="Number in the Series" className={classes.textField} style={{width:'20%', minWidth:'100px'}} type="number"
                  value="6"
                  variant="outlined"
                  />
                  
                  <TextField id="seriesTotal" label="Total Number of Teachings in the Series" className={classes.textField} style={{width:'35%', minWidth:'100px'}} type="number"
                  value="6"
                  variant="outlined"
                  />
                
                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6">
                  Tell us about the teaching...
                </Typography>

                <TextField id="summary" label="Teaching Summary" className={classes.textField} style={{width:'60%'}} required 
                  value="Jesus Christ was God's plan for bridging the gap between the natural man and the spiritual realm. In this teaching, we look at select examples in the Gospel of John in which Jesus Christ explained how holy spirit works and demonstrated how to see things spiritually. We also look at the example of Samuel when he first learned to hear from God by revelation, and we cover from verses in some New Testament epistles that highlight specifics about what the gift of the holy spirit means for us."
                  multiline
                  variant="outlined"
                  helperText="Please give a brief summary of what this teaching is about to be used as a teaching description in our catalog."   
                />

                <TextField id="scriptures" label="Scriptures Covered" className={classes.textField} style={{width:'60%'}} required 
                  value="John 1:11-12
John 1:17-18
John 1:32-33
John 3:1-8
1 Corinthians 2:12-14
John 4:7-14
John 4:23-24
John 7:38-39
John 14:16-18
John 16:7
John 16:13
Acts 1:1-5
1 Corinthians 14:18
Ephesians 6:12
John 20:21-22
Matthew 16:21-23
1 Samuel 3:1-10
Philippians 2:13"
                  multiline
                  variant="outlined"
                  helperText="Please list, in order, all of the Scriptures the teacher turns to during the teaching (for example: Romans 8:1
                    [enter] Ephesians 2:6-10 [enter] Romans 10:9 etc.)"
                    />

                <FormGroup className={classes.checkBoxes}>
                  <FormLabel style={{marginTop: '20px'}} component="legend">Topics Covered</FormLabel>

                  <Typography variant="body1" color="textSecondary" style={{marginLeft: '10px', marginTop: '10px'}}>
                    Please check the box next to any and all of the following topics that are covered in this teaching             
                  </Typography>

                  <Grid container spacing={3}>
                    {translatedTopics.map( (column, i) => 
                      (
                      <Grid item xs={5} key={"column"+i}>
                        {column.map( topic =>
                          <React.Fragment key={topic}>
                          <FormControlLabel
                          control={
                            <Checkbox
                            checked={(topic == "Receiving the holy spirit and the Manifestations" && true)}
                            />
                          }
                          label = {topic}
                          />
                          <br/>
                          </React.Fragment>
                        )}
                      </Grid>
                      )
                    )}
                  </Grid>
                  
                </FormGroup>

                <br />

                <TextField id="additionalTopics" label="Additional Topics" className={classes.textField} style={{width:'60%'}} 
                  value="Jesus Christ's Example"
                  variant="outlined"
                  multiline
                  helperText="If you think that the topic(s) of the teaching
                  is not covered by one of the choices above, please enter any
                  other topics or subjects you think this teaching should be able to be found under, separated by a comma. (i.e. Commitment, Discipline)"
                  />
                
                
                <br />

                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6" gutterBottom>
                  Additional information...
                </Typography>

                <TextField id="references" label="References" className={classes.textField} style={{width:'60%'}} 
                  value=""
                  variant="outlined"
                  multiline
                  helperText="Please mention here if the teacher made reference to 
                  a particular book other than the Bible, or a poem, a song, or an event. 
                  You could also catalog if there is a notable quote, story or analogy that was memorable."
                />

                <br />

                <TextField id="comments" label="Comments" className={classes.textField} style={{width:'60%'}} 
                  value=""
                  variant="outlined"
                  multiline
                  helperText="Please use this area to identify anything else you feel we should know 
                  but for which there is no specific field to enter"
                />

                </form>
                <Divider className={classes.divider}/>
                <Typography className={classes.heading} variant="h6" gutterBottom>
                  Example 2
                </Typography>
                <Divider className={classes.divider}/>
                <form autoComplete="off">
                <Grid container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  className={classes.root}>
                  <Grid style={{padding:"10px"}}>
                      <Typography variant="body1" color="textSecondary">
                          Pray Without Ceasing                             
                      </Typography>
                  </Grid>
                  <Grid>
                    <ReactAudioPlayer
                        src="https://cdn.harborlightfellowship.com/wp-content/uploads/2019/08/10114448/2015-Spring-Advance-1-Pray-Without-Ceasing-Jerry-Carr.mp3"
                        controls={true}
                        listenInterval={1000}
                        />
                  </Grid>        
              </Grid>

                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6" gutterBottom>
                  Which teaching was this?
                </Typography>

                <TextField id="title" label="Teaching Title" required className={classes.textField}
                  value="Pray Without Ceasing"
                  variant="outlined"
                  multiline
                  />

                <TextField className={classes.textField} 
                  id="teacher"
                  select
                  label="Teacher"
                  value="Carr, Jerry"
                  variant="outlined"
                >
                  <MenuItem value="Carr, Jerry">
                      Carr, Jerry
                  </MenuItem>
                </TextField>

                <TextField id="date" 
                  label="Teaching Date"
                  className={classes.textField}
                  value="20150501"
                  variant="outlined"
                  />

                <br/>

                <TextField className={classes.textField} 
                  style={{width:'39%'}}
                  id="location"
                  select
                  label="Location"
                  value="Greenkill Conference Center"
                  variant="outlined"
                  >
                  <MenuItem value="Greenkill Conference Center">
                      Greenkill Conference Center
                  </MenuItem>
                </TextField>

                <br/>

                <FormControlLabel control={
                  <Checkbox checked={true} />
                  }
                  label = "This teaching is part of a series"
                />

                <br/>
              
                  <TextField id="seriesName" label="Series Name" className={classes.textField} style={{width:'60%'}} 
                  value="2015 Spring Advance - Pray Without Ceasing"
                  variant="outlined"       
                  />
                  <br/>
                  <TextField id="seriesNumber" label="Number in the Series" className={classes.textField} style={{width:'20%', minWidth:'100px'}} type="number"
                  value="1"
                  variant="outlined"
                  />
                  
                  <TextField id="seriesTotal" label="Total Number of Teachings in the Series" className={classes.textField} style={{width:'35%', minWidth:'100px'}} type="number"
                  value="9"
                  variant="outlined"
                  />
                
                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6">
                  Tell us about the teaching...
                </Typography>

                <TextField id="summary" label="Teaching Summary" className={classes.textField} style={{width:'60%'}} required 
                  value="This teaching opens with the theme verse, 1 Thessalonians 5:17, covering God's desire that we pray without intermission throughout our day. It handles how we have to believe that God exists and that He really cares for and pays attention to us. Then, several quotes on prayer that portray God's heart are shared, and examples of prayer in the Gospels and Church Epistles are touched on. Lastly, the teaching covers how prayer is sharing our hearts and lives with God, making specific requests, supplications for self or others, intercession, and thanksgiving."
                  multiline
                  variant="outlined"
                  helperText="Please give a brief summary of what this teaching is about to be used as a teaching description in our catalog."   
                  />

                <TextField id="scriptures" label="Scriptures Covered" className={classes.textField} style={{width:'60%'}} required 
                  value="1 Thessalonians 5:17
                  Matthew 21:22
                  Hebrews 11:6
                  1 Peter 5:7
                  Psalms 25:1-22
Matthew 21:22
Mark 1:35
Romans 1:8
1 Corinthians 1:4-5
Philippians 1:9
Psalms 6:9
Proverbs 15:8
Psalms 18:19"
                  multiline
                  variant="outlined"
                  helperText="Please list, in order, all of the Scriptures the teacher turns to during the teaching (for example: Romans 8:1
                    [enter] Ephesians 2:6-10 [enter] Romans 10:9 etc.)"
                    />

                <FormGroup className={classes.checkBoxes}>
                  <FormLabel style={{marginTop: '20px'}} component="legend">Topics Covered</FormLabel>

                  <Typography variant="body1" color="textSecondary" style={{marginLeft: '10px', marginTop: '10px'}}>
                    Please check the box next to any and all of the following topics that are covered in this teaching             
                  </Typography>

                  <Grid container spacing={3}>
                    {translatedTopics.map( (column, i) => 
                      (
                        <Grid item xs={5} key={"column"+i}>
                        {column.map( topic =>
                          <React.Fragment key={topic}>
                          <FormControlLabel
                          control={
                            <Checkbox
                            checked={(topic == "Prayer" || topic == "Believing" && true)}
                            />
                          }
                          label = {topic}
                          />
                          <br/>
                          </React.Fragment>
                        )}
                      </Grid>
                      )
                    )}
                  </Grid>

                  
                </FormGroup>

                <br />

                <TextField id="additionalTopics" label="Additional Topics" className={classes.textField} style={{width:'60%'}} 
                  value=""
                  variant="outlined"
                  multiline
                  helperText="If you think that the topic(s) of the teaching
                  is not covered by one of the choices above, please enter any
                  other topics or subjects you think this teaching should be able to be found under, separated by a comma. (i.e. Commitment, Discipline)"
                  />
                
                
                <br />

                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6" gutterBottom>
                  Additional information...
                </Typography>

                <TextField id="references" label="References" className={classes.textField} style={{width:'60%'}} 
                  value="In His Presence By E.W. Kenyon
                  Quotes George Mueller"
                  variant="outlined"
                  multiline
                  helperText="Please mention here if the teacher made reference to 
                  a particular book other than the Bible, or a poem, a song, or an event. 
                  You could also catalog if there is a notable quote, story or analogy that was memorable."
                  />

                <br />

                <TextField id="comments" label="Comments" className={classes.textField} style={{width:'60%'}} 
                  value=""
                  variant="outlined"
                  multiline
                  helperText="Please use this area to identify anything else you feel we should know 
                  but for which there is no specific field to enter"
                  />

                </form>

                  </React.Fragment>
                  :
                  <React.Fragment>
                <Divider className={classes.divider}/>
                <Typography className={classes.heading} variant="h6" gutterBottom>
                  Ejemplo 1
                </Typography>
                <Divider className={classes.divider}/>
                <form autoComplete="off">
                <Grid container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  className={classes.root}>
                  <Grid style={{padding:"10px"}}>
                      <Typography variant="body1" color="textSecondary">
                      Un estilo de vida decente y con orden                          
                      </Typography>
                  </Grid>
                  <Grid>
                    <ReactAudioPlayer
                        src="https://cdn.harborlightfellowship.com/wp-content/uploads/2022/07/07113041/2013-Avanzada-Ecuador-10-Decente-y-con-orden-Ana-Lucia-Rodriguez-.mp3"
                        controls={true}
                        listenInterval={1000}
                        />
                  </Grid>        
              </Grid>

                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6" gutterBottom>
                ¿Cuál enseñanza es esta?
                </Typography>

                <TextField id="title" label="Titulo de la Enseñanza" required className={classes.textField}
                  value="Un estilo de vida decente y con orden"
                  variant="outlined"
                  multiline
                  />

                <TextField className={classes.textField} 
                  id="teacher"
                  select
                  label="Maestro"
                  value="Rodriguez, Ana Lucia"
                  variant="outlined"
                  >
                  <MenuItem value="Rodriguez, Ana Lucia">
                  Rodriguez, Ana Lucia
                  </MenuItem>
                </TextField>

                <TextField id="date" 
                  label="Fecha en la cual enseñada"
                  className={classes.textField}
                  value="20131013"
                  variant="outlined"
                  />

                <br/>

                <TextField className={classes.textField} 
                  style={{width:'39%'}}
                  id="location"
                  select
                  label="Ubicación"
                  value="Hostería Sueños del Mar (Puerto Cayo, Ecuador)"
                  variant="outlined"
                  >
                  <MenuItem value="Hostería Sueños del Mar (Puerto Cayo, Ecuador)">
                  Hostería Sueños del Mar (Puerto Cayo, Ecuador)
                  </MenuItem>
                </TextField>

                <br/>

                <FormControlLabel control={
                  <Checkbox checked={true} />
                }
                label = "Esta enseñanza es parte de una serie: "
                />

                <br/>
              
                  <TextField id="seriesName" label="Nombre de la Serie" className={classes.textField} style={{width:'60%'}} 
                  value="Avanzada Ecuador 2013 - El estilo de vida de un creyente"
                  variant="outlined"       
                  />
                  <br/>
                  <TextField id="seriesNumber" label="Número dentro de la serie" className={classes.textField} style={{width:'20%', minWidth:'100px'}} type="number"
                  value="8"
                  variant="outlined"
                  />
                  
                  <TextField id="seriesTotal" label="Número Total de Enseñanzas en Esta Serie" className={classes.textField} style={{width:'35%', minWidth:'100px'}} type="number"
                  value="10"
                  variant="outlined"
                  />
                
                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6">
                Cuéntanos acerca de la enseñanza...
                </Typography>

                <TextField id="summary" label="Resumen de la Enseñanza" className={classes.textField} style={{width:'60%'}} required 
                  value="Nuestro Dios es un Dios de bello y armonioso orden y ese orden está reflejado en todo lo Él ha hecho y hace, incluyendo Su Palabra. Cuando ponemos esa Palabra en nuestras mentes y la practicamos, nuestras vidas y hogares y reuniones de creyentes están en alineación y armonía con Dios y Su voluntad. En esta enseñanza consideramos cinco diferentes aspectos de nuestras vidas que queremos llegar a tener bien ordenados – nuestras mentes, nuestras cosas físicas, nuestro tiempo, nuestras familias, y nuestras reuniones de creyentes – para así glorificarle a Él y recibir las abundantes bendiciones que Él quiere para nosotros."
                  multiline
                  variant="outlined"
                  helperText="Por favor de un breve resumen de de qué se trata esta enseñanza que se pueda utilizar como su descripción en nuestro catalogo."   
                />

                <TextField id="scriptures" label="Escrituras Enseñadas" className={classes.textField} style={{width:'60%'}} required 
                  value="1 Corintios 14:40
                  Hechos 17:24
                  Salmos 119:133
                  Hebreos 4:12
                  2 Corintios 10:3-5
                  Hechos 19:19-20
                  Efesios 5:15-17
                  1 Corintios 11:3
                  1 Timoteo 3:4
                  Romanos 13:1
                  Filipenses 2:2-4
                  1 Corintios 14:26"
                  multiline
                  variant="outlined"
                  helperText="Por favor enumere, en el orden en que fueron abordadas, todas las referencias Biblicas a las cuales el maestro fue durante la enseñanza. Presione la tecla ENTER o INTRO despues de cada referencia. (Por ejemplo: Romanos 8:1 [tecla ENTER o INTRO] Efesios 2:6-10 [tecla ENTER o INTRO] Romanos 10:9 etcétera.)"
                  />

                <FormGroup className={classes.checkBoxes}>
                  <FormLabel style={{marginTop: '20px'}} component="legend">Temas Tratados</FormLabel>

                  <Typography variant="body1" color="textSecondary" style={{marginLeft: '10px', marginTop: '10px'}}>
                  Por favor hagan clic en la casilla de cualquier y todo tema que ha sido cubierto en esta enseñanza             
                  </Typography>

                  <Grid container spacing={3}>
                    {translatedTopics.map( (column, i) => 
                      (
                        <Grid item xs={5} key={"column"+i}>
                        {column.map( topic =>
                          <React.Fragment key={topic}>
                          <FormControlLabel
                          control={
                            <Checkbox
                            checked={(topic == "Mente renovada" && true)}
                            />
                          }
                          label = {topic}
                          />
                          <br/>
                          </React.Fragment>
                        )}
                      </Grid>
                      )
                      )}
                  </Grid>

                  
                </FormGroup>

                <br />

                <TextField id="additionalTopics" label="Temas Adicionales" className={classes.textField} style={{width:'60%'}} 
                  value=""
                  variant="outlined"
                  multiline
                  helperText="Si crees que el tema/los temas de la enseñanzas no está bien cubierta por una de las opciones abajo, por favor ingrese cualquier otro tema o tópico bajo el cual ud cree que se debería poder encontrar esta enseñanza, separandolos con comas. (Ejemplo: Compromiso, Disciplina)"
                  />
                
                
                <br />

                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6" gutterBottom>
                Información adicional...
                </Typography>

                <TextField id="references" label="Referencias" className={classes.textField} style={{width:'60%'}} 
                  value="Durante la enseñanza, la maestra lee una porción del prefacio del libro 'Ordena mis pasos en Tu Palabra' por el Dr. Wierwille, el Volumen 5 de Estudios en la Vida Abundante."
                  variant="outlined"
                  multiline
                  helperText="Si el maestro hizo referencia a algun poema, canción, evento o libro que no sea la Biblia, por favor menciónelo aqui. Tambión pueden catalogar aquí si el maestro hizo alguna cita o contó alguna historia o dió alguna analogía memorable."
                />

                <br />

                <TextField id="comments" label="Observaciones" className={classes.textField} style={{width:'60%'}} 
                  value=""
                  variant="outlined"
                  multiline
                  helperText="Por favor utilice esta área para señalar cualquier otra cosa que creen que debemos tomar en cuenta pero para la cual no hay un campo específico en el cual ingresarlo. Por ejemplo, si se encontraron con una sección de Escritura que no estaban seguros cómo catalogar, pueden mencionarlo aquí para que nosotros la revisemos antes de que se publique en el sitio web la información acerca de la enseñanza."
                />

                </form>

                <Divider className={classes.divider}/>
                <Typography className={classes.heading} variant="h6" gutterBottom>
                  Ejemplo 2
                </Typography>
                <Divider className={classes.divider}/>
                <form autoComplete="off">
                <Grid container
                  alignItems="center"
                  alignContent="center"
                  justifyContent="center"
                  className={classes.root}>
                  <Grid style={{padding:"10px"}}>
                      <Typography variant="body1" color="textSecondary">
                      Dios no nos deja ni nos desampara                        
                      </Typography>
                  </Grid>
                  <Grid>
                    <ReactAudioPlayer
                        src="https://cdn.harborlightfellowship.com/wp-content/uploads/2022/07/07113045/Dios-no-nos-deja-ni-nos-desampara.mp3"
                        controls={true}
                        listenInterval={1000}
                        />
                  </Grid>        
              </Grid>

                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6" gutterBottom>
                ¿Cuál enseñanza es esta?
                </Typography>

                <TextField id="title" label="Titulo de la Enseñanza" required className={classes.textField}
                  value="Dios no nos deja ni nos desampara"
                  variant="outlined"
                  multiline
                  />

                <TextField className={classes.textField} 
                  id="teacher"
                  select
                  label="Maestro"
                  value="Chatten, Bob"
                  variant="outlined"
                  >
                  <MenuItem value="Chatten, Bob">
                  Chatten, Bob
                  </MenuItem>
                </TextField>

                <TextField id="date" 
                  label="Fecha en la cual enseñada"
                  className={classes.textField}
                  value="20160421"
                  variant="outlined"
                />

                <br/>

                <TextField className={classes.textField} 
                  style={{width:'39%'}}
                  id="location"
                  select
                  label="Ubicación"
                  value="En línea"
                  variant="outlined"
                >
                  <MenuItem value="En línea">
                  En línea
                  </MenuItem>
                </TextField>

                <br/>

                <FormControlLabel control={
                  <Checkbox checked={true} />
                }
                label = "Esta enseñanza es parte de una serie: "
                />

                <br/>

                <TextField id="seriesName" label="Nombre de la Serie" className={classes.textField} style={{width:'60%'}} 
                  value="Grabaciones de vez en cuando"
                  variant="outlined"       
                  />
                  <br/>
                  <TextField id="seriesNumber" label="Número dentro de la serie" className={classes.textField} style={{width:'20%', minWidth:'100px'}} type="number"
                  value="54"
                  variant="outlined"
                  />
                  
                  <TextField id="seriesTotal" label="Número Total de Enseñanzas en Esta Serie" className={classes.textField} style={{width:'35%', minWidth:'100px'}} type="number"
                  value=""
                  variant="outlined"
                  />
                
                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6">
                Cuéntanos acerca de la enseñanza...
                </Typography>

                <TextField id="summary" label="Resumen de la Enseñanza" className={classes.textField} style={{width:'60%'}} required 
                  value="Hay muchas cosas en la vida que van más allá de lo que nosotros podemos tratar por nuestra propia fuerza o conocimiento o recursos y las crisis de la vida nos pueden alejar de Dios o ser motivo de acercarnos más a Él. Pero sin importar lo que nos esté sucediendo, Dios promete a través de Su Palabra que nunca nos va a dejar ni desamparar. Asociadas con esa promesa están muchas características de Dios y cosas que Él dice que puede y quiere hacer por nosotros si acudimos a Él. En esta enseñanza veremos varias de ellas, cosas que nos permiten vivir sin temor y con confianza porque, a fin de cuentas, la victoria viene por medio de Su poder y sabiduría y recursos, no los nuestros."
                  multiline
                  variant="outlined"
                  helperText="Por favor de un breve resumen de de qué se trata esta enseñanza que se pueda utilizar como su descripción en nuestro catalogo."   
                  />

                <TextField id="scriptures" label="Escrituras Enseñadas" className={classes.textField} style={{width:'60%'}} required 
                  value="Salmos 46:1-2
                  Salmos 18:1-3
                  Salmos 18:6
                  Salmos 18:16-19
                  Salmos 139:5-11
                  Éxodo 3:11-14
                  Salmos 34:4-10
                  Jeremías 20:11
                  Josué 1:8-9
                  Hebreos 13:5-6"
                  multiline
                  variant="outlined"
                  helperText="Por favor enumere, en el orden en que fueron abordadas, todas las referencias Biblicas a las cuales el maestro fue durante la enseñanza. Presione la tecla ENTER o INTRO despues de cada referencia. (Por ejemplo: Romanos 8:1 [tecla ENTER o INTRO] Efesios 2:6-10 [tecla ENTER o INTRO] Romanos 10:9 etcétera.)"
                />

                <FormGroup className={classes.checkBoxes}>
                  <FormLabel style={{marginTop: '20px'}} component="legend">Temas Tratados</FormLabel>

                  <Typography variant="body1" color="textSecondary" style={{marginLeft: '10px', marginTop: '10px'}}>
                  Por favor hagan clic en la casilla de cualquier y todo tema que ha sido cubierto en esta enseñanza             
                  </Typography>

                  <Grid container spacing={3}>
                    {translatedTopics.map( (column, i) => 
                      (
                        <Grid item xs={5} key={"column"+i}>
                        {column.map( topic =>
                          <React.Fragment key={topic}>
                          <FormControlLabel
                          control={
                            <Checkbox
                            checked={(topic == "Creencia" || topic == "Mente renovada" && true)}
                            />
                          }
                          label = {topic}
                          />
                          <br/>
                          </React.Fragment>
                        )}
                      </Grid>
                      )
                      )}
                  </Grid>

                  
                </FormGroup>

                <br />

                <TextField id="additionalTopics" label="Temas Adicionales" className={classes.textField} style={{width:'60%'}} 
                  value="Presencia de Dios, Temor"
                  variant="outlined"
                  multiline
                  helperText="Si crees que el tema/los temas de la enseñanzas no está bien cubierta por una de las opciones abajo, por favor ingrese cualquier otro tema o tópico bajo el cual ud cree que se debería poder encontrar esta enseñanza, separandolos con comas. (Ejemplo: Compromiso, Disciplina)"
                  />
                
                
                <br />

                <Divider className={classes.divider}/>

                <Typography className={classes.heading} variant="h6" gutterBottom>
                Información adicional...
                </Typography>

                <TextField id="references" label="Referencias" className={classes.textField} style={{width:'60%'}} 
                  value=""
                  variant="outlined"
                  multiline
                  helperText="Si el maestro hizo referencia a algun poema, canción, evento o libro que no sea la Biblia, por favor menciónelo aqui. Tambión pueden catalogar aquí si el maestro hizo alguna cita o contó alguna historia o dió alguna analogía memorable."
                  />

                <br />

                <TextField id="comments" label="Observaciones" className={classes.textField} style={{width:'60%'}} 
                  value=""
                  variant="outlined"
                  multiline
                  helperText="Por favor utilice esta área para señalar cualquier otra cosa que creen que debemos tomar en cuenta pero para la cual no hay un campo específico en el cual ingresarlo. Por ejemplo, si se encontraron con una sección de Escritura que no estaban seguros cómo catalogar, pueden mencionarlo aquí para que nosotros la revisemos antes de que se publique en el sitio web la información acerca de la enseñanza."
                />

                </form>
                </React.Fragment>}
                              
                          </CardContent>
                      </Card>
                      </Grid>
                      </Grid>
          </React.Fragment>
        );
      }
}

function mapStateToProps(state) {
  return {
      language: state.currentTranslations
  }
}

export default connect(mapStateToProps, {setPage, setCurrentTeaching, saveAudioPlayed})(withStyles(styles)(injectIntl(ExamplesPage)));