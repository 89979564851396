import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import React from "react";
import {render} from 'react-dom';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import CatalogPage from './pages/CatalogPage';
import TeachingsPage from './pages/TeachingsPage';
import LoginPage from './pages/LoginPage';
import InstructionsPage from './pages/InstructionsPage';
import ExamplesPage from './pages/ExamplesPage';
import AdminPage from './pages/AdminPage';
import { Provider } from 'react-redux'
import configureStore from './store/store';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { connect } from 'react-redux';
import theme from './theme';
import { PersistGate } from 'redux-persist/integration/react';
import HeaderBar from './components/HeaderBar';
import AudioBar from './components/AudioBar';
import Translator from './components/Translator';
import {SnackbarProvider} from 'notistack';
import translation from './i18n/en.json';
import './index.css';

class Index extends React.Component{

      render(){
        return (
            <Router>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <IntlProvider locale={this.props.currentLocale || 'en'} messages={this.props.currentTranslations || translation}>
                            {this.props.currentUser &&
                                <React.Fragment>
                                    <HeaderBar/>
                                    <Translator />
                                    <AudioBar/>
                                </React.Fragment>
                            }
                            <Route exact path="/" render={() => this.props.currentUser ? <Redirect to="/Instructions"/> : <Redirect to="/Login/"/> } />
                            <Route exact path="/Login" component={LoginPage}/>
                            <Route exact path="/Instructions" render={() => this.props.currentUser ? <InstructionsPage/> : <Redirect to="/Login/"/> } />
                            <Route exact path="/Examples" render={() => this.props.currentUser ? <ExamplesPage/> : <Redirect to="/Login/" /> } />
                            <Route exact path="/Teachings" render={() => this.props.currentUser ? <TeachingsPage/> : <Redirect to="/Login/"/> } />
                            <Route exact path="/Catalog" render={() => this.props.currentUser && this.props.currentTeaching ? <CatalogPage/>
                                : this.props.currentUser && !this.props.currentTeaching ? <Redirect to="/Instructions"/> : <Redirect to="/Login/"/> } />
                            <Route exact path="/Admin" render={() => this.props.currentUser && this.props.adminAccess ? <AdminPage/> : <div>Unauthorized</div>} />
                        </IntlProvider>
                    </ThemeProvider>
                </StyledEngineProvider>
            </Router>
        );
    }
}

function mapStateToProps( state ){
    return{
        currentUser: state.currentUser,
        currentTeaching: state.currentTeaching,
        adminAccess : state.adminAccess,
        currentTranslations : state.currentTranslations && state.currentTranslations.translations,
        currentLocale : state.currentTranslations && state.currentTranslations.locale
    }           
}

const ConnectedIndex = connect(mapStateToProps)(Index);

const{store,persistor} = configureStore();

render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <SnackbarProvider maxSnack={3}>
                <ConnectedIndex />
            </SnackbarProvider>
        </PersistGate>
    </Provider>,document.getElementById('root')
);
