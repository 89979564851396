import React, { Component } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {getIsUserAdmin, getIsUserCataloger, getUserById, getUserByUsername} from '../api/api';
import {setCurrentUser, setAdminAccess} from '../actions/actions';
import {Redirect} from 'react-router';
import Loader from 'react-loader-spinner';
import queryString from 'query-string';
import {decryptUserId, checkPassword} from '../utils';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import Translator from '../components/Translator';

const styles = {
    container: {
        position: 'relative',
        backgroundColor: 'gray',
    },
    button:{
        margin: '5px'
    },
    divider:{
        marginTop: '10px',
        marginBottom: '10px'
    },
    textField:{
        width:'90%'
    },
    card: {
        width: 'inherit',
        height: 'inherit',
        backgroundColor: '',
        margin:'20px',
    },
    links:{
        textDecoration: 'none'
    }
};


class LoginPage extends Component {

    constructor(){
        super();
        this.state = {
            username: '',
            password: '',
            showIncorrectPasswordMessage: false,
            showIncorrectPermissionsMessage: false,
            fireRedirect:false
        }
        this.login = this.login.bind(this);
    }

    componentDidMount(){
        // See if token query string param exists
        const values = queryString.parse(this.props.location.search);
        // if it does, decrypt it and login with userid
        if (values.token){
            var userId = decryptUserId(decodeURIComponent(values.token));
            if (userId){
                this.login(null, userId, true);
            }
        }
    }

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
    };

    login(e, userId, isSSO){
        if (e){
            e.preventDefault();
        }
        this.setState({loading:true});
        // If SSO, get by ID, otherwise get user by entered username
        var getUserPromise = isSSO ? () => getUserById(userId) : () => getUserByUsername(this.state.username);

        getUserPromise().then( user => {
            if (!user || !checkPassword(this.state.password, user.user_pass)){
                this.setState({showIncorrectPasswordMessage: true});
                this.setState({showIncorrectPermissionsMessage: false});
                this.setState({loading:false});
                return;
            }

            var isCatalogerPromise = getIsUserCataloger(user.id);
            var isAdminPromise = getIsUserAdmin(user.id);
            Promise.all([isCatalogerPromise, isAdminPromise]).then(results => {
                var isCataloger = results[0];
                var isAdmin = results[1];
                if (!isCataloger && !isAdmin){
                    this.setState({showIncorrectPasswordMessage: false});
                    this.setState({showIncorrectPermissionsMessage: true});
                    this.setState({loading:false});
                    return;
                }

                this.props.setCurrentUser(user);
                this.props.setAdminAccess(isAdmin);
    
                this.setState({fireRedirect:true});
                this.setState({loading:false});

            });
        });
    } 

  render() {

    const { classes } = this.props;
    const {intl} = this.props;
    const messages = defineMessages({
        pageTitle:{
          description: "Login page title",
          defaultMessage: "Login - Harbor Light Fellowship"
        }
    });

    document.title = intl.formatMessage(messages.pageTitle);
    return (
        <div className={classes.container}>
            <Translator />
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={10} md={4}>
                    <Card className={classes.card}>
                        <CardContent>
                            { this.state.loading ?
                            <div style={{display: 'flex', justifyContent: 'center'}}>

                                <Loader type="Oval" color="black" height="40" width="40"/>
                                <Typography style={{padding:'10px'}} variant="body2">
                                <FormattedMessage
                                    description="Loading message"
                                    defaultMessage="Loading..."
                                />
                                </Typography>

                            </div>
                            :
                            <React.Fragment>
                                <form onSubmit={this.login}>
                                <Typography variant="h5">
                                <FormattedMessage
                                    description="Header of login box where user enters email address and password"
                                    defaultMessage="Login"
                                />
                                </Typography>
                                <Divider className={classes.divider}/>
                                <Typography variant="body2" style={{marginBottom:0}} >
                                    <FormattedMessage
                                        description="Label on box to enter email address for login"
                                        defaultMessage="Email address"
                                    />                 
                                </Typography>
                                <TextField
                                    id="username"
                                    required
                                    className={classes.textField}
                                    onChange={this.handleChange('username')}
                                    margin="normal"
                                    />
                                <Typography variant="body2" style={{marginBottom:0}}>
                                    <FormattedMessage
                                        description="Label on box to enter password for login"
                                        defaultMessage="Password"
                                    />                 
                                </Typography>
                                <TextField
                                    id="password"
                                    required
                                    type="password"
                                    className={classes.textField}
                                    onChange={this.handleChange('password')}
                                    margin="normal"
                                />
                                <div>
                                    {this.state.showIncorrectPasswordMessage ? 
                                    <div style={{color:'red'}}>
                                        <FormattedMessage
                                            description="Message to show when user enters incorrect email address or password"
                                            defaultMessage="Incorrect email address or password"
                                        />  
                                    </div> 
                                    : this.state.showIncorrectPermissionsMessage ?
                                    <div style={{color:'red'}}>
                                        <FormattedMessage
                                            description="Message to show when user does not have cataloging permissions"
                                            defaultMessage="The cataloging function is for those who are trained to catalog content on our site. If you are interested in information on cataloging, please contact us at support@harborlightfellowship.com."
                                        />  
                                    </div> 
                                    : ''}
                                </div>                               
                                
                                    <Button
                                        style={{margin:'20px', float:'right'}}
                                        variant="contained"
                                        color="primary"
                                        type="submit">
                                        Login
                                    </Button>
                                {this.state.fireRedirect && (<Redirect to="/Instructions"/>)}
                                                                    
                                </form>
                            </React.Fragment>                         
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
  }
}

export default connect(null, { setCurrentUser, setAdminAccess })(withStyles(styles)(injectIntl(LoginPage)));