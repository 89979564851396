import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { setLanguage } from '../actions/actions'
import { loadTranslation } from '../i18n';

const styles = theme => ({
    languageButton: {
        background: 'none',
        border: 'none',
        padding: '0',
        color: '#069',
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    languageTitle: {
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '5px',
        fontFamily: 'Open Sans, Arial, Helvetica, sans-serif;',
        fontWeight: '400',
        display: 'inline-block',
        verticalAlign: 'middle'
    },
    languageIcon: {
        paddingTop: '10px',
        verticalAlign: 'middle',
        display: 'inline-block'
    }
});

class Translator extends Component {

    flushLocale = async (locale) => {
        const translations = await loadTranslation(locale);
        this.props.setLanguage({translations, locale});
      };

    render(){
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid
                justifyContent="flex-end"
                container>
                    <Grid item>                 
                        <button className={classes.languageButton} onClick={() => this.flushLocale('en')}>
                            <span className={classes.languageIcon}>
                                <img src="data:image/svg+xml;utf8,<svg width='21' height='15' xmlns='http://www.w3.org/2000/svg'><defs><linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='a'><stop stop-color='%23FFF' offset='0%'/><stop stop-color='%23F0F0F0' offset='100%'/></linearGradient><linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='b'><stop stop-color='%23D02F44' offset='0%'/><stop stop-color='%23B12537' offset='100%'/></linearGradient><linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='c'><stop stop-color='%2346467F' offset='0%'/><stop stop-color='%233C3C6D' offset='100%'/></linearGradient></defs><g fill='none' fill-rule='evenodd'><path fill='url(%23a)' d='M0 0h21v15H0z'/><path d='M0 0h21v1H0V0zm0 2h21v1H0V2zm0 2h21v1H0V4zm0 2h21v1H0V6zm0 2h21v1H0V8zm0 2h21v1H0v-1zm0 2h21v1H0v-1zm0 2h21v1H0v-1z' fill='url(%23b)'/><path fill='url(%23c)' d='M0 0h9v7H0z'/><path d='M1.5 2a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm-5 1a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm1 1a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm1 1a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm2 0a.5.5 0 110-1 .5.5 0 010 1zm1 1a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1zm-2 0a.5.5 0 110-1 .5.5 0 010 1z' fill='url(%23a)'/></g></svg>" alt="English"/>
                            </span>
                            <span className={classes.languageTitle}>English</span>
                        </button>
                    </Grid>
                    <Grid item>
                        <button className={classes.languageButton} onClick={() => this.flushLocale('es')}>
                            <span className={classes.languageIcon}>
                                <img src="data:image/svg+xml;utf8,<svg width='21' height='15' xmlns='http://www.w3.org/2000/svg'><defs><linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='a'><stop stop-color='%23FFF' offset='0%'/><stop stop-color='%23F0F0F0' offset='100%'/></linearGradient><linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='b'><stop stop-color='%23DD172C' offset='0%'/><stop stop-color='%23C60B1F' offset='100%'/></linearGradient><linearGradient x1='50%' y1='0%' x2='50%' y2='100%' id='c'><stop stop-color='%23FFD133' offset='0%'/><stop stop-color='%23FFC500' offset='100%'/></linearGradient></defs><g fill='none' fill-rule='evenodd'><path fill='url(%23a)' d='M0 0h21v15H0z'/><path fill='url(%23b)' d='M0 0h21v4H0zm0 11h21v4H0z'/><path fill='url(%23c)' d='M0 4h21v7H0z'/><path fill='%23FFEDB1' d='M5.5 7h1v.5h-1z'/><path d='M4.915 8.46c.025.295.291.54.585.54s.56-.246.585-.54L6.207 7H4.793l.122 1.46zm-.624-1.465a.456.456 0 01.465-.495h1.488a.45.45 0 01.465.495l-.126 1.506A1.104 1.104 0 015.5 9.5a1.104 1.104 0 01-1.083-.999L4.29 6.995z' fill='%23A41517' fill-rule='nonzero'/><path fill='%23A41517' d='M4.5 7.5h2V8H6l-.5 1L5 8h-.5zM3 6h1v3.5H3zm4 0h1v3.5H7zm-2.5-.5c0-.276.214-.5.505-.5h.99c.279 0 .505.232.505.5v.248A.248.248 0 016.255 6h-1.51a.245.245 0 01-.245-.252V5.5z'/></g></svg>" alt="Español"/>
                            </span>
                            <span className={classes.languageTitle}>Español</span>
                        </button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentLocale: state.currentTranslations
    }
}

export default connect(mapStateToProps, {setLanguage})(withStyles(styles)(Translator));

