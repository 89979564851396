import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography/Typography';
import ReactAudioPlayer from 'react-audio-player';
import { saveAudioPlayed } from '../actions/actions'
import Loader from 'react-loader-spinner';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
    links:{
        textDecoration:'none'
    },
    chooseTeachingButton:{
        marginBottom:'5px',
    },
    root: {
        borderStyle:'solid',
        borderWidth:'1px',
        borderTopColor:'white',
        borderBottomColor:'#e5e5e5',
        borderRightColor:'white',
        borderLeftColor:'white',
        padding:'10px'
    }
});

class AudioBar extends Component {
    constructor(props){
        super(props);
        this.state = {
            seeked:false,
            loading:true
        }
    } 

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.audioPlayed === undefined){
            return {
                played: nextProps.audioPlayed,
                loading: true
            };
        }else{
            return {
                played: nextProps.audioPlayed,
                loading: false
            };
        }
    }

    onCanPlayThrough(rap){
        if (rap != null && this.state.played){
            if (!this.state.seeked){
                if (this.state.played !== 0){
                    if (parseInt(rap.audioEl.current.currentTime) !== parseInt(this.state.played)){
                        rap.audioEl.current.currentTime = this.state.played;
                    }
                }
            }else{
                if (parseInt(rap.audioEl.current.currentTime) !== parseInt(this.state.played)){
                    this.setState({seeked:false});
                }
            }
            
            // For some reason needed for iOS devices..
            if (rap.audioEl.current.currentTime === 0 && parseInt(this.state.played) !== 0){
                rap.audioEl.current.currentTime = this.state.played;
            }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.props.currentTeaching != null ?
                this.state.loading ?
                        <div style={{display: 'flex', justifyContent: 'center', paddingTop:'10px'}}>
                            <Loader type="Oval" color="black" height="40" width="40"/>
                            <Typography gutterBottom style={{padding:'10px'}} variant="body1" color="textSecondary">
                                <FormattedMessage
                                    description="Text to display when audio is loading"
                                    defaultMessage="Loading audio..."
                                />
                            </Typography>
                        </div>
                        : 
                <Grid container
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                    className={classes.root}>
                    <Grid style={{padding:"10px"}}>
                        <Typography variant="body1" color="textSecondary">
                            {this.props.currentTeaching != null ? this.props.currentTeaching.post_title : ''}                               
                        </Typography>
                    </Grid>
                    <Grid>
                        
                        <ReactAudioPlayer
                            src={this.props.currentTeaching !=null ? this.props.currentTeaching.guid : ''}
                            //preload={this.state.iosDevice ? "none" : "metadata"}
                            controls={true}
                            ref={(element) => {this.rap = element;}}
                            listenInterval={1000}                    
                            onListen={() => {
                                if (this.rap!=null && this.rap.audioEl.current.currentTime > 1){
                                    this.props.saveAudioPlayed(this.rap.audioEl.current.currentTime);
                                }
                            }}
                            onCanPlayThrough={() => this.onCanPlayThrough(this.rap)}                                                                                                                                                         
                            onPause={() => {
                                if (this.rap != null){
                                    this.setState({played: this.rap.audioEl.current.currentTime});
                                }
                            }}
                            onSeeked={() => {
                                if (this.rap != null){
                                    this.setState({seeked:true});
                                }
                            }}

                            />
                    </Grid>        
                </Grid>
                : ''}
            </React.Fragment>
        );
  }
}

function mapStateToProps(state) {
    return {
        currentTeaching: state.currentTeaching,
        audioPlayed: state.audioPlayed
    }
}

export default connect(mapStateToProps, {saveAudioPlayed})(withStyles(styles)(AudioBar));

