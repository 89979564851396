import {
    SET_CURRENT_TEACHING,
    SET_CURRENT_USER, 
    SIGN_OUT,
    SET_TEACHING_META, 
    SAVE_AUDIO_PLAYED,
    SET_ADMIN_ACCESS,
    SET_PAGE,
    SET_LANGUAGE
} from './actionTypes';

export const setCurrentTeaching = teaching => ({type: SET_CURRENT_TEACHING, payload: teaching});

export const setTeachingMeta = metas => ({type: SET_TEACHING_META, payload: metas});

export const setCurrentUser = user => ({type: SET_CURRENT_USER, payload: user});

export const setAdminAccess = bool => ({type: SET_ADMIN_ACCESS, payload: bool});

export const saveAudioPlayed = time => ({type: SAVE_AUDIO_PLAYED, payload: time});

export const signOut = () => ({type: SIGN_OUT, payload: null});

export const setPage = page => ({type: SET_PAGE, payload: page});

export const setLanguage = language => ({type: SET_LANGUAGE, payload: language});