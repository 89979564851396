import React, { Component } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Fab from '@mui/material/Fab';
import RemoveIcon from '@mui/icons-material/Remove';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Loader from 'react-loader-spinner'
import azure from 'azure-storage';
import {createPostMeta, deletePostMeta, updatePostMeta, getAvailableTeachingsByUser, getChosenTeachings, getSubmittedTeachings, getUserByUsername } from '../api/api';
import { setPage, setCurrentTeaching, saveAudioPlayed } from '../actions/actions';
import {teachingSorter} from '../utils';
import { defineMessage, FormattedMessage, injectIntl } from 'react-intl';

const styles = theme => ({
    links:{
        textDecoration: 'none'
    },
    teachingText:{
        fontSize:'12pt',
        [theme.breakpoints.down('sm')]:{
            fontSize:'11pt',
          },
    },
    seriesText:{
        fontSize:'10pt',
        fontStyle:'italic',
        paddingLeft:'10px',
        [theme.breakpoints.down('sm')]:{
            fontSize:'10pt',
          },
    },
});


const TeachingItem =  (props) => (
    <TableRow key = {props.teaching.ID}>
        {props.submitted &&
            <TableCell padding="none" style={{textAlign:'center'}}>
                <Fab
                variant="circular"
                size="small"
                style={{marginTop: '5px', marginBottom: '5px'}}
                onClick={() => props.unsubmitTeaching(props.teaching)}>
                    <RemoveIcon />
                </Fab>
            </TableCell>
        }
        <TableCell style={{padding: '5px'}}>
            <div className={props.style.teachingText}>{props.teaching.post_title}</div>
            {props.teaching.isSeries && <div className={props.style.seriesText}>{props.teaching.resource_series_title + (props.teaching.seriesNumber ? ': #' + props.teaching.seriesNumber : '')}</div>}
        </TableCell>
        <TableCell style={{padding: '5px'}}>
            {props.teaching.loggerDisplayName}
        </TableCell>
        
        <TableCell style={{padding: '5px'}}>
            { 
            props.available ?
                <Fab
                    variant="extended"
                    color="primary" 
                    onClick={ () => {
                        window.scrollTo(0,0);
                        props.toggleAssign(props.teaching);
                    }
                }>
                    Assign
                </Fab>
            :
            props.chosen ?
                <Fab variant="extended" color="default" 
                    onClick={ () => {
                        props.unassignTeaching(props.teaching);
                    }
                }>
                    <FormattedMessage
                        description="Button to click to unassign a teaching from a user"
                        defaultMessage="Unassign"
                    />
                </Fab>
            :
            <React.Fragment>
            <Link to="/Catalog?submitted=true" style={{textDecoration:'none'}}>
                <Fab variant="extended" color="default" 
                    onClick={ () => {
                        window.scrollTo(0,0);
                        if (!props.currentTeaching || props.currentTeaching.ID !== props.teaching.ID){
                            props.setCurrentTeaching(props.teaching);
                        }
                    }
                }>
                    <FormattedMessage
                        description="Button to click to view a submitted teaching"
                        defaultMessage="View"
                        />
                </Fab>
            </Link>
            <Fab variant="extended" color="default" 
            onClick={ () => {
                props.setTeachingCompleted(props.teaching);
            }
            }>
                <FormattedMessage
                    description="Button to click to remove a teaching from a user"
                    defaultMessage="Remove"
                    />
            </Fab>
        </React.Fragment>
            }
        </TableCell>
    </TableRow>
);

class TeachingsPage extends Component {

    constructor(){
        super();
        this.state = {
            availableTeachings: [ ],
            chosenTeachings: [ ],
            submittedTeachings: [ ],
            assignOpen : false,
            assigningTeaching : null,
            assignee: ''
        }
        this.assignTeaching = this.assignTeaching.bind(this);
        this.unassignTeaching = this.unassignTeaching.bind(this);
        this.unsubmitTeaching = this.unsubmitTeaching.bind(this);
        this.setTeachingCompleted = this.setTeachingCompleted.bind(this);
        this.toggleAssign = this.toggleAssign.bind(this);
    }

    toggleAssign(teaching){
        if (!this.state.assignOpen){
            this.setState({assignOpen: !this.state.assignOpen});
        }
        this.setState({ assigningTeaching: teaching });
    }

    assignTeaching(teaching){
        if (this.state.assignee.length !==0 ){
            getUserByUsername(this.state.assignee)
            .then( user => {
                if (user){
                    createPostMeta({
                        "post_id": teaching.ID,
                        "meta_key": "logger_id",
                        "meta_value": user.id.toString()
                    });
                    teaching.loggerDisplayName = user.display_name;
                    this.setState(
                        {
                            chosenTeachings: this.state.chosenTeachings.concat(teaching),
                            availableTeachings: this.state.availableTeachings.filter(e => e !== teaching),
                            assignee: '',
                            assigningTeaching: null,
                            assignOpen : false
                        }
                    );
                }
            });
        }
    }

    handleChange = name => event => {
        this.setState({
          [name]: event.target.value,
        });
      };

    unsubmitTeaching(teaching){
        // delete log_status postmeta
        deletePostMeta(teaching.statusMetaId);

        // delete submitted data
        var blobService = azure.createBlobService("DefaultEndpointsProtocol=https;AccountName=biblestudy;AccountKey=xww0LbJfiYcJ6KVbq4SlMcWMzA+OwF8RAoZAFxnwFSxUc/nQ7aVfhKQ8ae3EwECvZVlg4wZfAM9432wy7lI+sw==;EndpointSuffix=core.windows.net");
        blobService.deleteBlobIfExists("submitted", teaching.ID.toString() + '.txt', function(error){});
        
        this.setState({chosenTeachings: this.state.chosenTeachings.concat(teaching).sort(teachingSorter)});
        this.setState({submittedTeachings: this.state.submittedTeachings.filter(e => e !== teaching)});
    }

    setTeachingCompleted(teaching){
        updatePostMeta(teaching.statusMetaId, { "post_id": teaching.ID, "meta_key": "log_status", "meta_value": "uploaded" } );
        this.setState({submittedTeachings: this.state.submittedTeachings.filter(e => e !== teaching)});
    }

    unassignTeaching(teaching){
        // delete logger_id postmeta
        deletePostMeta(teaching.loggerMetaId)
        
        // delete saved data
        var blobService = azure.createBlobService("DefaultEndpointsProtocol=https;AccountName=biblestudy;AccountKey=xww0LbJfiYcJ6KVbq4SlMcWMzA+OwF8RAoZAFxnwFSxUc/nQ7aVfhKQ8ae3EwECvZVlg4wZfAM9432wy7lI+sw==;EndpointSuffix=core.windows.net");
        blobService.deleteBlobIfExists("saved", teaching.ID.toString() + '.txt', function(error){
        });
        teaching.loggerDisplayName = null;
        
        this.setState({availableTeachings: this.state.availableTeachings.concat(teaching).sort(teachingSorter)});
        this.setState({chosenTeachings: this.state.chosenTeachings.filter(e => e !== teaching)});
    }
    
    componentDidMount(){
        this.props.setPage('Admin');
        // Clear current teaching in case admin unassigns the current teaching
        this.props.setCurrentTeaching(null);
        this.props.saveAudioPlayed(undefined);
        this.getTeachings();
    }
    
    getTeachings(){
        this.setState({loading:true});

        var availablePromise = getAvailableTeachingsByUser(this.props.currentUser.id, true);
        var chosenPromise = getChosenTeachings();
        var submittedPromise = getSubmittedTeachings();
        Promise.all([chosenPromise, availablePromise, submittedPromise]).then(results => {
            var chosenTeachings = results[0].sort(teachingSorter);
            var availableTeachings = results[1].sort(teachingSorter);
            var submittedTeachings = results[2].sort(teachingSorter);
            this.setState({
                availableTeachings:availableTeachings,
                chosenTeachings:chosenTeachings,
                submittedTeachings:submittedTeachings,
                loading:false
            });
        });
    }

  render() {
    const { classes } = this.props;
    const availableTeachingItems = this.state.availableTeachings.map((teaching, i) => {
        return (
            <TeachingItem
                key={i}
                available={true}
                chosen={false}
                submitted={false}
                teaching={teaching} 
                toggleAssign={this.toggleAssign}
                style={classes}
            />
        )
    });

    const chosenTeachingItems = this.state.chosenTeachings.map((teaching, i) => {
        return (
            <TeachingItem
                key={i}
                available={false}
                chosen={true}
                submitted={false}
                teaching={teaching}
                unassignTeaching={this.unassignTeaching}
                style={classes}
            />
        )
    });

    const submittedTeachingItems = this.state.submittedTeachings.map((teaching, i) => {
        return (
            <TeachingItem
                key={i}
                available={false}
                chosen={false}
                submitted={true}
                teaching={teaching}
                unassignTeaching={this.unassignTeaching}
                unsubmitTeaching={this.unsubmitTeaching}
                setTeachingCompleted={this.setTeachingCompleted}
                style={classes}
                setCurrentTeaching={this.props.setCurrentTeaching}
            />
        )
    });

    const {intl} = this.props;
    const message = defineMessage({
        description: "Admin page title",
        defaultMessage: "Admin - Harbor Light Fellowship"
    });
    document.title = intl.formatMessage(message);
    return (
        <React.Fragment>
            <Grid container justifyContent = "center">
                {this.state.assignOpen &&
                <Grid item>
                    <Typography gutterBottom variant="h3" style={{padding:'15px'}}>
                    <FormattedMessage
                        description="Assign a teaching to a user"
                        defaultMessage="Assign Teaching: {teachingTitle}"
                        values={{
                            teachingTitle: this.state.assigningTeaching.post_title
                        }}
                    />
                    </Typography>
                    <TextField id="assignee" label="Username"
                        value={this.state.asignee}
                        variant="outlined"
                        onChange={this.handleChange('assignee')}
                    />
                    <Fab
                        variant="extended"
                        color="primary" 
                        onClick={ () => {
                            this.assignTeaching(this.state.assigningTeaching);
                        }}
                    >
                        <FormattedMessage
                            description="Assign a teaching to a user"
                            defaultMessage="Assign"
                        />
                    </Fab>
                </Grid>
                }
                <Grid item xs={12} md={10}>                   
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography gutterBottom variant="h5">
                                <FormattedMessage
                                    description="Header for teachings that are assigned to users"
                                    defaultMessage="Assigned Teachings"
                                />
                            </Typography>
                            {chosenTeachingItems.length >= 1 ?
                            
                            <React.Fragment>
                                <Typography gutterBottom variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Subtitle of teachings that are assigned to users"
                                        defaultMessage="Teachings already assigned to a user"
                                    />
                                </Typography> 
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="subtitle1" style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Table header for teaching title column"
                                                        defaultMessage="Teaching"
                                                    />
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="subtitle1" style={{fontWeight:'bold'}}>
                                                    <FormattedMessage
                                                        description="Table header for teaching asignee name"
                                                        defaultMessage="Asignee"
                                                    />
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {chosenTeachingItems}               
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                            : this.state.loading ? 
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Loader type="Oval" color="black" height="40" width="40"/>
                                <Typography gutterBottom style={{padding:'10px'}} variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Text to display when loading teachings"
                                        defaultMessage="Loading teachings..."
                                    />
                                </Typography>
                            </div>
                            :                            
                            <Typography gutterBottom variant="body1" color="textSecondary">
                                <FormattedMessage
                                    description="Text to display when no teachings are currently assigned"
                                    defaultMessage="No teachings are currently assigned."
                                />
                                
                            </Typography>
                            
                            }
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography gutterBottom variant="h5">
                                <FormattedMessage
                                    description="Header for teachings that are available to catalog"
                                    defaultMessage="Available Teachings"
                                />                          
                            </Typography>
                            {availableTeachingItems.length >= 1 ?
                            
                            <React.Fragment>                         
                                <Typography gutterBottom variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Subtitle of teachings that are not assigned to users"
                                        defaultMessage="Teachings that have not been assigned to a user"
                                    />                   
                                </Typography> 
                                <Table>
                                    <TableBody>
                                        {availableTeachingItems}               
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                            : this.state.loading ? 
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Loader type="Oval" color="black" height="40" width="40"/>
                                <Typography gutterBottom style={{padding:'10px'}} variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Text to display when loading teachings"
                                        defaultMessage="Loading teachings..."
                                    />
                                </Typography>
                            </div>
                            :  
                            <Typography gutterBottom variant="body1" color="textSecondary">
                                <FormattedMessage
                                    description="Text to display when all available teachings have been assigned"
                                    defaultMessage="All available teachings have been assigned."
                                />
                                
                            </Typography>                             
                            }
                        </CardContent>
                    </Card>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography gutterBottom variant="h5">
                                <FormattedMessage
                                    description="Header for teachings that are submitted"
                                    defaultMessage="Submitted Teachings"
                                />                     
                            </Typography>
                            {submittedTeachingItems.length >= 1 ?
                            
                            <React.Fragment>                         
                                <Typography gutterBottom variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Subtitle of teachings that have been submitted"
                                        defaultMessage="Teachings that have been submitted..."
                                    />    
                                </Typography> 
                                <Table>
                                    <TableBody>
                                        {submittedTeachingItems}               
                                    </TableBody>
                                </Table>
                            </React.Fragment>
                            : this.state.loading ?
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Loader type="Oval" color="black" height="40" width="40"/>
                                <Typography gutterBottom style={{padding:'10px'}} variant="body1" color="textSecondary">
                                    <FormattedMessage
                                        description="Text to display when loading teachings"
                                        defaultMessage="Loading teachings..."
                                    />
                                </Typography>
                            </div>
                            :  
                            <Typography gutterBottom variant="body1" color="textSecondary">
                                <FormattedMessage
                                    description="Text to display when no teachings have been submitted"
                                    defaultMessage="No submitted teachings."
                                />     
                            </Typography>                             
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
  }
}

function mapStateToProps( state ){
    return{
        currentUser: state.currentUser,
    }          
    
}

export default connect(mapStateToProps, {setPage, setCurrentTeaching, saveAudioPlayed})(withStyles(styles)(injectIntl(TeachingsPage)));
